export default {
	state: {
		dd_name: '',
		dd_opened: false,
		private_flag:false,
		dd_position:{
			x:0,y:0,width:0,height:0
		},

		overlay: false,
		overlay_target: '',
	},
	mutations: {
		SetDDName(state,name){
			state.dd_name = name;
		},
		SetDDStatus(state,status){
			state.dd_opened = status;
			state.overlay = status;
		},
		SetPrivateFlag(state,flag){
			state.private_flag = flag;
		},
		SetDDPosition(state,position){
			state.dd_position = {...position};
		}
	},
	actions: {},
	getters: {}
}
