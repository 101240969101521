<template>
  <li>
    <label class="interest">
      <input type="checkbox" v-if="selected" checked @change="Delete">
      <input type="checkbox" v-else @change="Save">
      <span class="interest-content">
        <i>
          <img :src="api_server+'/'+ data.icon1">
          <!--          <inline-svg-->
          <!--              :src="api_server + data.icon"-->
          <!--          ></inline-svg>-->
          <!--          <inline-svg
                        :src="api_server + '/' + data.icon"
                        transformSource="transformSvg"
                        @loaded="svgLoaded($event)"
                        @unloaded="svgUnloaded()"
                        @error="svgLoadError($event)"
                        width="150"
                        height="150"
                        fill="black"
                        aria-label="My image"
                    ></inline-svg>-->
          <!--          <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/>
                      <path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/>
                    </svg>-->
        </i>
        <span>{{ data.title }}</span>
      </span>
    </label>
  </li>
</template>

<script>
// import InlineSvg from 'vue-inline-svg';
import {mapActions, mapState} from "vuex";

export default {
  name: "UP_Interests_Elem",
  components: {
    // InlineSvg
  },
  props: {
    data: Object,
    selected: Boolean
  },
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
    }),
  },
  methods: {
    ...mapActions(['PostCall', 'DeleteCall']),
    Save() {
      this.PostCall({
        endpoint: '/users/add_interest',
        options: {id: this.data.id},
        callback: (data) => {
          console.log(data);
          // this.SetUserData(data);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    },
    Delete() {
      this.DeleteCall({
        endpoint: '/users/delete_interests/' + this.data.id,
        options: '',
        callback: (data) => {
          this.SetUserData(data);
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    },
  }
}
</script>

<style scoped>

</style>