<template>
  <div class="chat-subject" v-if="chat_type===2 && letter">
    <div class="chat-subject-text">{{ letter.subject }}</div>
    <time class="chat-subject-date">{{ letter.date }}</time>
  </div>
  <div class="chat-window">
    <img src="img/logo.svg" alt="">
    <div class="chat-window-date" v-if="chat_type===1 || chat_type===0">
      <time class="chat-window-date-box">12<span>th</span> Aug</time>
    </div>
    <div class="chat-window-box">
      <perfect-scrollbar
          :watchOptions="true"
          :options="scroll_options"
          class="DDHolder">
        <div v-if="chat_type===0 && chat_data" class="chat-window-main">
          <template v-for="(value, index) in chat_data.messages" v-bind:key="index">
            <div
                class="chat-message"
                :class="value.self?'chat-message-self':''">
              <div
                  class="chat-message-text"
                  :class="true ?'chat-message-icon':''">
                <div class="chat-message-text-content">{{ value.message }}</div>
                <button type="button" class="chat-message-translate"></button>
                <button type="button" class="chat-message-icon-warning" v-if="value.alert"></button>
              </div>

              <time class="chat-message-time">10:25</time>
            </div>
          </template>
        </div>
        <div v-if="chat_type===1 && chat_data" class="chat-window-main">
          <template v-for="(value, index) in chat_data.messages" v-bind:key="index">
            <div
                class="chat-message"
                :class="value.self?'chat-message-self':''"
            >
              <div
                  class="chat-message-text"
                  :class="true ?'chat-message-icon':''">
                <div class="chat-message-text-content" v-if="value.deleted">Message was deleted by user</div>
                <div class="chat-message-text-content" v-else>{{ value.content.text }}</div>
                <button type="button" class="chat-message-translate"></button>
                <button type="button" class="chat-message-icon-warning" v-if="value.alert"></button>
              </div>

              <time class="chat-message-time">{{ value.time }}</time>
            </div>
          </template>
        </div>
        <div v-if="chat_type===2 && letter" class="chat-window-main">
          <div class="chat-letter-box">
            <div class="chat-letter-main">
              <p>{{ letter.text }}</p>
            </div>
            <div class="chat-letter-actions">
              <button type="button" class="chat-letter-translate"></button>
              <button type="button" class="chat-letter-warning"></button>
            </div>
          </div>
          <template v-if="letter.photo">
            <div class="chat-letter-box" v-if="letter.photo.length > 0">
              <div class="chat-letter-row">
                <template v-for="(value,index) in letter.photo" :key="index">
                  <div class="chat-letter-row-col">
                    <div class="chat-media">
                      <div class="chat-media-box">
                        <div class="chat-media-buy">
                          <picture>
                            <!--                        <source srcset="pic/avatar-female-big.webp 1x, pic/avatar-female-big@2x.webp 2x" type="image/webp">-->
                            <!--                        <source srcset="pic/avatar-female-big.jpg 1x, pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">-->
                            <img :src="value.preview_link" alt="">
                          </picture>
                          <div class="chat-media-photo"></div>
                        </div>
                      </div>
                      <div class="chat-media-text"><strong>{{ value.price }} coins</strong>{{ value.name }}</div>
                    </div>
                  </div>
                </template>
                <!--              <template v-for="(value,index) in letter.video" :key="index">-->
                <!--                <div class="chat-letter-row-col">-->
                <!--                  <div class="chat-media">-->
                <!--                    <div class="chat-media-box">-->
                <!--                      <div class="chat-media-buy">-->
                <!--                        <picture>-->
                <!--                          &lt;!&ndash;                          <source srcset="pic/avatar-female-big.webp 1x, pic/avatar-female-big@2x.webp 2x" type="image/webp">&ndash;&gt;-->
                <!--                          &lt;!&ndash;                          <source srcset="pic/avatar-female-big.jpg 1x, pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">&ndash;&gt;-->
                <!--                          <img :src="value.preview_link" alt="">-->
                <!--                        </picture>-->
                <!--                        <div class="chat-media-video"></div>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="chat-media-text"><strong>{{ value.price }} coins</strong>{{ value.name }}</div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </template>-->
              </div>
            </div>
          </template>
        </div>

      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CHT_Window",
  computed: {
    ...mapState({
      chat_type: state => state.Chat.chat_type,
      chat_data: state => state.Chat.chat_data,
      letter: state => state.Chat.letter,
    }),
  },
  props: {
    //messages: Array,
  },
  data: () => {
    return {
      letter2: {
        id: 10,
        reply_to_id: 9,
        date: '5 Mar 2021 at 10:42',
        subj: 'This is what the subject look like. This is what the subject look like.',
        text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed, It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposedIt is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed',
        photo: [
          {id: 0, preview_link: '', price: 100500, name: 'photo 1'},
          {id: 1, preview_link: '', price: 100500, name: 'photo 2'},
        ],
        video: [
          {id: 0, preview_link: '', price: 100500, name: 'video 1'},
          {id: 1, preview_link: '', price: 100500, name: 'video 2'},
        ],
      },
      //1 - text , 2 - photo
      /*
      messages: [
         {
           "id": 1,
           "type": 1,
           "content": {
             "text": "hi there, how do you do?"
           },
           "self": false
         },
         {
           "id": 2,
           "type": 1,
           "content": {
             "text": "hi i'm ok"
           },
           "self": true
         },
         {
           id: 0,
           type: 'text',
           text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. It is a long established fact that a reader will be distracted by the readable content',
           time: '10:53',
           deleted: true,
           self: false,
         },
         {
           id: 1,
           type: 'text',
           text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. It is a long established fact that a reader will be distracted by the readable content',
           time: '10:55',
           deleted: false,
           self: false
         },
         {
           id: 2,
           type: 'text',
           text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using. It is a long established fact that a reader will be distracted by the readable content',
           time: '11:02',
           self: true
         },
         {
           id: 3,
           type: 'photo',
           time: '11:03',
           img: '',
           deleted: true,
           self: false,
           free: true,
         },
         {
           id: 4,
           type: 'photo',
           time: '11:05',
           img: 'pic/avatar-female-big.jpg',
           deleted: false,
           self: false,
           free: false,
         },
         {
           id: 5,
           type: 'photo',
           time: '11:06',
           img: 'pic/avatar-female-big.jpg',
           deleted: false,
           self: false,
           free: true,
         },
         {
           id: 6,
           type: 'photo',
           time: '11:07',
           img: 'pic/avatar-female-big.jpg',
           deleted: false,
           self: true,
           free: true,
         },
       ],*/
      scroll_options: {
        suppressScrollX: true,
      }
    }
  },
}
</script>

<style scoped>

</style>