<template>
  <main>
    <ul class="page-tabs">
      <li>
        <div class="page-tabs-invite"
             :class="chat_type === 0 ? 'is-active' : ''"
             @click="ChangeTab(0)">
          <span>Invite</span>
        </div>
      </li>
      <li>
        <div class="page-tabs-chat"
             :class="chat_type === 1 ? 'is-active' : ''"
             @click="ChangeTab(1)">
          <span>Chat</span>
        </div>
      </li>
      <li>
        <div class="page-tabs-letter"
             :class="chat_type === 2 ? 'is-active' : ''"
             @click="ChangeTab(2)">
          <span>Letter</span>
        </div>
      </li>
    </ul>
    <div class="container">
      <template v-if="!write_letter">
        <CHT_Invites v-if="chat_type===0"/>
        <CHT_Chats v-if="chat_type===1"/>
        <CHT_Letters v-if="chat_type===2"/>
      </template>
      <CHT_WriteLetter v-else/>
    </div>
  </main>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import CHT_WriteLetter from "@/components/04Chat/Sub/CHT_WriteLetter";
import CHT_Invites from "@/components/04Chat/Sub/CHT_Invites";
import CHT_Chats from "@/components/04Chat/Sub/CHT_Chats";
import CHT_Letters from "@/components/04Chat/Sub/CHT_Letters";


export default {
  name: "Chat",
  components: {CHT_WriteLetter, CHT_Invites, CHT_Chats, CHT_Letters},
  computed: {
    ...mapState({
      chat_id: state => state.Chat.chat_id,
      chat_type: state => state.Chat.chat_type,
      write_letter: state => state.Chat.write_letter,
    }),
  },
  data: () => {
    return {}
  },
  mounted() {
  },
  methods: {
    ...mapActions(['GetCall']),
    ...mapMutations(['SetChatData', 'ResetChatData']),
    ChangeTab(chat_type) {
      this.ResetChatData();
      this.SetChatData({
        chat_type: chat_type,
      });
    },
  },
}
</script>

<style scoped>

</style>