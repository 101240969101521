<template>
  <div class="tip-bg" @click="CloseTip"></div>
  <div class="tip-holder"
       ref="tip_holder"
       :style="'left:'+calcPosition.x+'px; top:'+calcPosition.y+'px;'">
    <DDBurgerNav v-if="dd_name === 'menu'"/>
  </div>
</template>

<script>
import DDBurgerNav from "@/components/Dropdowns/Parts/DDBurgerNav";
import { mapMutations, mapState } from "vuex";

export default {
  name: "Dropdowns",
  components: { DDBurgerNav },
  computed: {
    ...mapState({
      dd_name: state => state.Dropdowns.dd_name,
      dd_position: state => state.Dropdowns.dd_position
    }),
  },
  data:()=>{
    return {
      calcPosition:{
        x:0,
        y:0
      }
    }
  },
  mounted() {
    console.log(this.$refs.tip_holder.clientWidth);

    if(this.dd_position.x > window.innerWidth/2){
      this.calcPosition.x = this.dd_position.x - this.$refs.tip_holder.clientWidth + this.dd_position.width
    }
    this.calcPosition.y = this.dd_position.y + this.dd_position.height + 20

  },
  methods:{
    ...mapMutations(['SetDDStatus']),
    CloseTip(){
      this.SetDDStatus(false);
    }
  }
}
</script>

<style lang="less" scoped>
.tip-bg{
  position: fixed;
  z-index: 9998;
  background: rgba(0,0,0,.01);
  width: 100%;
  height: 100%;
}
.tip-holder{
  position: fixed;
  z-index: 9999;
  .tip-content{
    display: block;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0 5px 20px rgba(0,0,0,.15);
    box-shadow: 0 5px 20px rgba(0,0,0,.15);
    color: #303030;
  }
}
</style>