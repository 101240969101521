<template>
  <div class="GSelect" :class="style_type==='dark' ? '':'GSelectLight'" :style="'z-index:'+ZIndex">
    <perfect-scrollbar
        :watchOptions="true"
        :options="scroll_options"
        :class="open ? 'show' : ''"
        class="DDHolder">
      <template v-for="(item,index) in filtered" :key="item[textLabel]">
        <div class="DDElement"
             @click="selectItem(index)"
             @mouseenter="activeIndex = index"
        >
          <slot name="label" v-bind:item="item">
            {{ item[textLabel] }}
          </slot>
        </div>
      </template>
    </perfect-scrollbar>
    <div class="i-holder">
      <input
          v-model="filteredText"
          :id="unic_id"
          type="text"
          @blur="close"
          @focus="focus"
          @keydown="handleKeyboard"
          autocomplete="off"
          :placeholder="placeholder"
          :style="input_type === '' ? 'cursor:pointer':''"
      >
      <div class="dd_icon"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GSelect",
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    error: {
      type: Array,
      default() {
        return [];
      },
    },
    textLabel: {
      type: String,
      default: 'text',
    },

    unic_id: String,
    input_type: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    list: Array,
    //search_val: String,
    style_type: {
      type: String,
      default: 'dark'
    },
    ZIndex: {
      type: String,
      default: '100'
    }
  },
  emits: [
    'update:modelValue'
  ],
  data: function () {
    return {
      open: false,
      //value: '',
      selectedItem: {},
      activeIndex: -1,
      filteredText: '',
      scroll_options: {
        suppressScrollX: true,
      }
    }
  },
  mounted() {
    if (this.modelValue !== undefined && this.modelValue !== null) {
      this.setFilteredText();
    }
  },
  methods: {
    focus() {
      this.open = true;

      const index = this.options.findIndex((item) => item[this.textLabel] === this.filteredText);
      this.filteredText = '';
      this.activeIndex = index;
      this.selectedItem = { ...this.options[index] };
      /*this.$nextTick(() => {
        this.$refs.container.scrollTop = index * 32;
      });*/
    },
    selectItem(index) {
      this.open = false;

      this.selectedItem = { ...this.filtered[index] };
      this.filteredText = this.selectedItem[this.textLabel] || '';
      this.sync();
    },
    handleKeyboard(e) {
      if (this.input_type === '') {
        e.preventDefault();
      } else {
        if (this.input_type === 'number') {
          if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105) && e.keyCode !== 8 && e.keyCode !== 46) {
            e.preventDefault();
          }
        }
        if (this.input_type === 'text') {
          if ((e.keyCode < 67 || e.keyCode > 90) && (e.keyCode < 70 || e.keyCode > 90) && e.keyCode !== 8 && e.keyCode !== 46) {
            e.preventDefault();
          }
        }
      }
      /*if (e.keyCode === 13) {
        this.selectItem(this.activeIndex);
        e.preventDefault();
        return false;
      }
      if (e.keyCode === 38) {
        this.activeIndex -= 1;
        if (this.activeIndex < 0) {
          this.activeIndex = 0;
        }
        e.preventDefault();
      } else if (e.keyCode === 40) {
        this.activeIndex += 1;
        if (this.activeIndex > this.options.length) {
          this.activeIndex -= 1;
        }
        e.preventDefault();
      } else {
        this.activeIndex = 0;
      }*/

      this.open = true;
    },
    sync() {
      this.$emit('update:modelValue', this.selectedItem.id);
    },
    close() {
      setTimeout(() => {
        this.open = false;
        this.filteredText = this.selectedItem[this.textLabel] || '';
      }, 250);
    },
    setFilteredText() {
      if (this.textLabel !== 'id') {
        console.log(this.options.findIndex(obj => {
          return obj.id === this.modelValue
        }));
        if (this.options.findIndex(obj => {
          return obj.id === this.modelValue
        }) > -1) {
          this.filteredText = this.options[this.options.findIndex(obj => {
            return obj.id === this.modelValue
          })][this.textLabel] || '';
        }
      } else {
        this.filteredText = this.modelValue || '';
      }
    }
  },
  computed: {
    filtered() {
      return this.options.filter(
          (item) => item[this.textLabel].toString().toLowerCase().includes(this.filteredText.toString().toLowerCase())
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.filteredText = '';
        this.selectedItem = {};
        if (this.modelValue !== '') {
          // console.log(this.modelValue);
          // this.sync();
          this.setFilteredText();
        }
      },
      deep: true,
    },
    modelValue: {
      handler(val) {
        if (val !== null && val !== undefined) {
          console.log(val);
          this.setFilteredText();
        }
      },
      deep: true,
    },
    loading(newVal) {
      if (newVal) {
        this.selecedtItem = {};
        this.filteredText = '';
      }
    },
  },

}
</script>

<style scoped></style>