<template>
  <li>
    <figure>
      <div class="media">
        <picture class="media-preview" @click="ViewPhoto">
          <!--          <source srcset="/pic/photo.webp 1x, /pic/photo@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="/pic/photo.jpg 1x, /pic/photo@2x.jpg 2x" type="image/jpeg">-->
          <img :src="api_server + '/' + photo.preview" alt="">
        </picture>
        <button type="button" class="media-remove" @click="Delete"></button>
      </div>
      <figcaption class="media-caption">
        <div class="scrollbar-inner">{{ photo.description }}</div>
      </figcaption>
    </figure>
  </li>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PhotoListElement",
  props: {
    photo: Object
  },
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus', 'SetPrivateFlag', 'DeletePhotoFromList', 'SetPhotoViewData']),
    ...mapActions(['DeleteCall']),

    ViewPhoto() {
      this.SetPhotoViewData({ photos: [this.photo], index: 0 });

      this.SetPrivateFlag(false);
      this.SetPopupName('view_photo');
      this.SetPopupStatus(true);
    },
    Delete() {
      this.DeleteCall({
        endpoint: '/users/delete_photo/' + this.photo.id,
        options: '',
        callback: () => {
          this.DeletePhotoFromList(this.photo);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },
  }
}
</script>

<style scoped>

</style>