export default {
  state: {
    popup_name: '',
    popup_opened: false,
    private_flag: false,

    overlay: false,
    overlay_target: '',
    photo_view_data: [],
    photo_view_index: 0,
  },
  mutations: {
    SetPopupName(state, name) {
      state.popup_name = name;
    },
    SetPopupStatus(state, status) {
      state.popup_opened = status;
      state.overlay = status;
    },
    SetPrivateFlag(state, flag) {
      state.private_flag = flag;
    },
    SetPhotoViewData(state, data) {
      state.photo_view_data = Array.from(data.photos);
      state.photo_view_index = data.index;
    },
    OverlayClick(state) {
      state.overlay = false;
      state.popup_opened = false;
    }
  },
  actions: {},
  getters: {}
}
