<template>
  <PAddPhotos v-if="popup_name==='add_photo'"/>
  <PAddAvatar v-if="popup_name==='add_avatar'"/>
  <PAddBg v-if="popup_name==='add_bg'"/>
  <PAddVideo v-if="popup_name==='add_video'"/>
  <PSearch v-if="popup_name==='search'"/>
  <PViewPhotos v-if="popup_name==='view_photo'"/>
  <PChatPhotoAdd v-if="popup_name==='chat_add'"/>
</template>

<script>
import { mapState } from "vuex";
import PAddPhotos from "@/components/Popups/parts/PAddPhotos";
import PAddAvatar from "@/components/Popups/parts/PAddAvatar";
import PAddBg from "@/components/Popups/parts/PAddBg";
import PAddVideo from "@/components/Popups/parts/PAddVideo";
import PSearch from "@/components/Popups/parts/PSearch";
import PViewPhotos from "@/components/Popups/parts/PViewPhotos";
import PChatPhotoAdd from "@/components/Popups/parts/PChatPhotoAdd";

export default {
  name: "Popups",
  components:{
    PChatPhotoAdd,
    PViewPhotos,
    PSearch,
    PAddPhotos,
    PAddAvatar,
    PAddBg,
    PAddVideo,
  },
  computed: {
    ...mapState({
      popup_name: state => state.Popups.popup_name,
    }),
  },
}
</script>

<style scoped>

</style>