<template>
  <dialog class="popup" id="add-photos"
       :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add photos</div>
      <form class="form">
        <div class="popup-content">
<!--          <div class="popup-icons">-->
<!--            <img src="img/facebook.svg" alt="">-->
<!--          </div>-->
          <fieldset>
            <label>Select Photo</label>
            <div class="textfield-clear file-input-holder">
              <input type="file" class="file-input"  @change="FileAdded" accept=".png,.jpg">
              <span v-if="file === null">*.png,*.jpg</span>
              <span v-else class="file-selected">{{ file.name }}</span>
              <button v-if="file !== null" type="button" @click="ClearFile"></button>
            </div>
          </fieldset>
          <fieldset>
            <label>Enter photo description</label>
            <div class="textfield-clear">
              <input type="text" class="textfield" v-model="description">
              <button type="button" v-if="description.length > 0" @click="ClearDescription"></button>
            </div>
          </fieldset>
<!--          <button type="button" class="button-link button-link-add">
            <i></i>
            <span>Add more link</span>
          </button>-->
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <button type="submit" class="button" @click="Save">Save photos</button>
          </div>
        </div>
      </form>
    </div>
  </dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PAddPhotos",
  data: function () {
    return {
      is_open: false,
      description:'',
      file: null,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd','SetUserData','AddPhotoToList']),
    ...mapActions(['PostCall',]),

    FileAdded(e){
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0){
        this.file = files[0];
      }
    },


    ClearFile(){
      this.file = null
    },

    ClearDescription(){
      this.description = ''
    },

    Save(e){
      e.preventDefault();

      let _file = this.file;

      const formData = new FormData();

      formData.append('type', 'photos');
      formData.append('description', this.description);
      formData.append('image', _file);

      this.PostCall({
        endpoint: '/users/add_photo',
        options: formData,
        callback: (data) => {
          if(data) {
            this.AddPhotoToList(data);
          }
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>