<template>
  <section class="box open-box"
           :class="is_opened ? 'is-opened':''">
    <div class="box-header">
      <h3>My presents – 16</h3>
      <div class="close-btn" @click="ToggleOpened"></div>
    </div>
    <div class="box-content">
      <div class="presents">
        <figure class="present">
          <picture class="present-image">
            <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
            <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
            <img src="/pic/present-1.png" alt="">
          </picture>
          <div class="present-user">
            <picture class="present-user-avatar">
              <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
              <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
              <img src="/pic/avatar.png" alt="">
            </picture>
            <div class="present-user-info">
              <span>Nicolas</span>
              <p>2021-05-22</p>
            </div>
          </div>
        </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-2.webp 1x, /pic/present-2@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-2.png 1x, /pic/present-2@2x.png 2x" type="image/png">
          <img src="/pic/present-2.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure><figure class="present">
        <picture class="present-image">
          <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">
          <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">
          <img src="/pic/present-1.png" alt="">
        </picture>
        <div class="present-user">
          <picture class="present-user-avatar">
            <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
            <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
            <img src="/pic/avatar.png" alt="">
          </picture>
          <div class="present-user-info">
            <span>Nicolas</span>
            <p>2021-05-22</p>
          </div>
        </div>
      </figure>
      </div>
    </div>
  </section>
</template>

<script>
//import { mapState } from "vuex";

export default {
  name: "UP_Gifts",
  data:()=>{
    return {
      is_opened:false,
    }
  },
  /*computed: {
    ...mapState({
      gifts: state => state.User.user_data.user,
    }),
  },*/

  methods:{
    ToggleOpened(){
      this.is_opened = !this.is_opened;
    }
  },
}
</script>

<style scoped>

</style>