<template>
  <div class="chat-user"
       :class="chat_user.user_id === user.user_id ? 'is-active':''"
       @click="UserClicked">
    <div class="chat-user-avatar" :class="user.online ? 'is-online':''">
      <picture>
        <!--              <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
        <!--              <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
        <img :src="api_server+'/'+user.img" alt="">
      </picture>
    </div>
    <div class="chat-user-info">
      <div class="chat-user-name-date">
        <div class="chat-user-name">{{ user.name }}</div>
        <time class="chat-user-date">{{ user.last_date }}</time>
      </div>
      <div class="chat-user-links-about">
        <ul class="chat-user-links">
          <li class="chat-user-links-photo"></li>
          <li class="chat-user-links-video"></li>
        </ul>
        <template v-if="letters">
          <div class="chat-user-about">{{ letters[0].message }}</div>
          <div class="chat-user-chain-dummy">{{ letters.length }}</div>
          <div class="chat-user-chain-toggle" @click.stop="LettersDDClicked">{{ letters.length }}</div>
        </template>
      </div>
    </div>
  </div>
  <div class="letters-holder"
       :class="user.opened ? 'is-opened':''"
       v-if="chat_type===2">
    <template v-for="(value2, index2) in letters" v-bind:key="index2">
      <perfect-scrollbar :watchOptions="true" :options="scroll_options" class="DDHolder">
        <template v-if="index2 > 0">
          <div class="chat-user chat-user-line" @click="LetterClicked(value2.id)">
            <div class="chat-user-chain"
                 :class="value2.incoming ? 'chat-user-chain-incoming' : 'chat-user-chain-outcoming'"></div>
            <div class="chat-user-info">
              <div class="chat-user-name-date">
                <time class="chat-user-date">{{ value2.time }}</time>
              </div>
              <div class="chat-user-links-about">
                <ul class="chat-user-links">
                  <li class="chat-user-links-photo"></li>
                  <li class="chat-user-links-video"></li>
                </ul>
                <div class="chat-user-about">{{ value2.message }}</div>
              </div>
            </div>
          </div>
        </template>
      </perfect-scrollbar>
    </template>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CHT_LetterElement",
  props: {
    user: Object,
  },
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
      chat_type: state => state.Chat.chat_type,
      chat_user: state => state.Chat.chat_user,
      chat_id: state => state.Chat.chat_id,
      chat_users_list: state => state.Chat.chat_users_list,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      letters: null,
    }
  },
  mounted() {
    if (this.user) {
      setTimeout(() => {this.GetLetters();}, 150);
    }
  },
  methods: {
    ...mapMutations(['SetChatData']),
    ...mapActions(['GetCall']),
    UserClicked() {//get_letters_flag

      if (this.user !== this.chat_id) {
        this.SetChatData({
          chat_id: this.user.user_id,
          chat_user: this.user,
        });
        this.GetLetters();
      } else {
        if (this.user.letters) {
          this.GetLetter();
        }
      }
    },

    GetLetters() {
      this.GetCall({
        endpoint: `/letters/${this.user.id}/`,
        options: {},
        callback: (data) => {
          this.letters = Array.from(data.letters);
          this.SetChatData({
            chat_data: data,
          });

          if (data.letters.length > 0) {
            this.GetLetter(data.letters[0].id);
          }
        },
      })
    },
    GetLetter(letter_id) {
      this.GetCall({
        endpoint: `/letters/${this.user.id}/letter/${letter_id}/`,
        options: {},
        callback: (data) => {
          this.SetChatData({
            letter: data,
          });
        },
      })
    },

    LetterClicked(letter_id) {
      let letter_index = this.letters.map(e => { return e.id }).indexOf(letter_id);

      this.letters.forEach(e => { e.active = false });
      this.letters[letter_index].active = true;

      this.GetLetter(letter_id);
    },

    LettersDDClicked() {
      let opened = this.user.opened ? true : false;
      let tempUserList = Array.from(this.chat_users_list);
      let user_index = tempUserList.map(e => { return e.user_id }).indexOf(this.user.user_id);
      tempUserList.forEach(e => { e.opened = false });
      tempUserList[user_index].opened = !opened;

      this.SetChatData({
        chat_users_list: tempUserList,
      });
    }

  },
}
</script>

<style scoped>

</style>