<template>
  <section class="box open-box"
           :class="is_opened ? 'is-opened':''">
    <div class="box-header">
      <div class="box-header-item">
        <h3>Add your videos</h3>
      </div>
      <div class="box-header-item">
        <button type="button" class="button-link button-link-add prevent-click"><i></i>Add video</button>
        <div class="close-btn" @click="ToggleOpened"></div>
      </div>
    </div>
    <div class="box-content">
      <ul class="media-list media-video">
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption</div>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <div class="media">
              <picture class="media-preview">
                <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">
                <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">
                <img src="/pic/video.jpg" alt="">
              </picture>
              <button type="button" class="media-remove"></button>
            </div>
            <figcaption class="media-caption">
              <div class="scrollbar-inner">Video caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption</div>
            </figcaption>
          </figure>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UP_Videos",
  data:()=>{
    return {
      is_opened:false,
    }
  },
  computed: {
    ...mapState({
      videos: state => state.User.user_data.videos,
    }),
  },

  methods:{
    ToggleOpened(){
      this.is_opened = !this.is_opened;
    }
  },
}
</script>

<style scoped>

</style>