<template>
  <textarea
      class="g_textfield autosize"
      v-model="val"
      :placeholder="placeholder"
      @change="sync"
  ></textarea>
</template>

<script>
export default {
  name: "GTextField",
  props:{
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: [String,Number],
  },
  data:()=>{
    return{
      val:'',
      externalChange: false,
    }
  },
  emits: [
    'update:modelValue'
  ],
  mounted() {
    this.externalChange =true;
    setTimeout(() => {
      if (this.modelValue) {
        this.val = this.modelValue;
      }
      this.externalChange = false;
    }, 50);
  },
  methods:{
    sync() {
      // console.log(this.selectedItem);
      this.$emit('update:modelValue', this.val);
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.externalChange =true;
        setTimeout(() => {
          if (newVal) {
            this.val = newVal;
          }
          this.externalChange = false;
        }, 50);
      },
      deep: false,
    },
  },
}
</script>

<style scoped></style>