<template>
  <section class="chat chat-letter-write">
    <div class="chat-letter-to">
      <span>To:</span>
      <div class="chat-letter-to-user">
        <div class="chat-user">
          <div class="chat-user-avatar">
            <picture>
              <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">
              <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">
              <img src="pic/avatar.png" alt="">
            </picture>
          </div>
          <div class="chat-user-info">
            <div class="chat-user-name">Yana</div>
          </div>
        </div>
      </div>
      <div @click="CloseReply" class="button button-transparent button-back">
        <span>Back</span>
      </div>
    </div>
    <div class="chat-letter-subject">
      <span>Subject:</span>
      <div class="chat-letter-textarea">
        <textarea class="textfield autosize" rows="1"></textarea>
        <button type="button" class="chat-letter-textarea-translate"></button>
      </div>
    </div>
    <div class="chat-letter-text">
      <div class="chat-letter-textarea">
        <textarea class="textfield"></textarea>
        <button type="button" class="chat-letter-textarea-translate"></button>
      </div>
    </div>
    <div class="chat-letter-bottom">
      <div class="chat-letter-price">Price: <span>50 coins</span></div>
      <ul class="chat-letter-buttons">
        <li>
          <button type="button" class="chat-letter-translate"></button>
        </li>
        <li>
          <button type="button" class="chat-letter-video"></button>
        </li>
        <li>
          <button type="button" class="chat-letter-photo"></button>
        </li>
        <li>
          <button type="button" class="chat-letter-smile"></button>
        </li>
        <li>
          <button type="submit" class="chat-letter-send-message"></button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "CHT_WriteLetter",
  methods: {
    ...mapMutations(['SetChatData']),
    CloseReply() {
      this.SetChatData({write_letter: false});
    }
  }
}
</script>

<style scoped>

</style>