<template>
  <section class="box">
    <div class="box-header">
      <h3>My lifestyle</h3>
    </div>
    <div class="box-content">
      <form class="form">
        <div class="form-cols">
          <template v-for="(value, index) in my_lifestyle_dd_data" v-bind:key="index">
            <template v-if="my_lifestyle.map(e => { return e.item_id; }).indexOf(value.id) > -1">
              <template v-for="(value2, index2) in my_lifestyle" v-bind:key="index2">
                <UP_MyLifestyle_Elem v-if="value.id === value2.item_id" :data="value" :selected="value2"/>
              </template>
            </template>
            <template v-else>
              <UP_MyLifestyle_Elem :data="value" :selected="undefined"/>
            </template>
          </template>

        </div>
      </form>
    </div>
  </section>
</template>

<script>
import UP_MyLifestyle_Elem from "@/components/01UserProfile/Parts/UP_MyLifestyle/UP_MyLifestyle_Elem";
import { mapState } from "vuex";

export default {
  name: "UP_MyLifestyle",
  components:{
    UP_MyLifestyle_Elem,
  },
  computed: {
    ...mapState({
      my_lifestyle: state => state.User.user_data.my_lifestyle,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
    }),
  },
}
</script>

<style scoped>

</style>