<template>
  <div class="chat-header">
    <div class="chat-user" v-if="chat_user">
      <div class="chat-user-avatar" :class="chat_user.online ? 'is-online':''">
        <picture>
          <!--          <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
<!--
          user_id: this.userList[0].user_id,
          name: this.userList[0].name,
          avatar: this.userList[0].img,
          status: this.userList[0].online,
          chat_time: {
          min: 0, sec: 0
          },
-->


          <img :src="api_server + '/' + chat_user.img" alt="">
        </picture>
      </div>
      <div class="chat-user-info">
        <h1 class="chat-user-name">{{ chat_user.name }}</h1>
        <div class="chat-user-status" v-if="chat_user.online">Online</div>
        <div class="chat-user-status" v-else>Offline</div>
      </div>
    </div>
    <div class="chat-header-gallery" v-if="chat_data">
      <div class="swiper-container">
        <swiper
            :slides-per-view="'auto'"
            :space-between="6"

            @swiper="onSwiper"
            @slideChange="onSlideChange"
            ref="swiper"
        >
          <template v-for="(value, index) in chat_data.user_photos" v-bind:key="index">
            <swiper-slide class="swiper-slide">
              <div class="chat-header-gallery-image" @click="OpenViewPhotos(index)">
                <picture>
                  <!--                <source srcset="pic/chat-header-gallery.webp 1x, pic/chat-header-gallery@2x.webp 2x" type="image/webp">-->
                  <!--                <source srcset="pic/chat-header-gallery.jpg 1x, pic/chat-header-gallery@2x.jpg 2x" type="image/jpeg">-->
                  <img :src="api_server+'/'+value.preview" alt="">
                </picture>
              </div>
            </swiper-slide>
          </template>

        </swiper>
      </div>
      <button type="button" class="swiper-prev" @click="PrevSlide"></button>
      <button type="button" class="swiper-next" @click="NextSlide"></button>
    </div>
    <div class="chat-header-toolbox" v-if="chat_user">
      <time class="chat-header-time">
<!--        <span><i>{{ chat_user.chat_time.min }}</i> min <i>{{ chat_user.chat_time.sec }}</i> sec</span>-->
        <button type="button" class="button button-pause">Stop Chat</button>
      </time>
      <ul class="chat-header-actions">
        <!--        <li>-->
        <!--          <button type="button" class="chat-header-actions-webcam"></button>-->
        <!--        </li>-->
        <li>
          <button type="button" class="chat-header-actions-fav"></button>
        </li>
        <li>
          <button type="button" class="chat-header-actions-block"></button>
        </li>
      </ul>
      <button type="button" class="chat-header-actions-toggle tip" data-tip="#chat-actions" data-tippy-placement="bottom-end"></button>
    </div>
  </div>
</template>

<script>
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

import { mapMutations, mapState } from "vuex";

export default {
  name: "CHT_Top",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
      chat_user: state => state.Chat.chat_user,
      chat_data: state => state.Chat.chat_data,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus', 'SetPhotoViewData', 'SetPrivateFlag']),
    OpenViewPhotos(index) {
      this.SetPhotoViewData({ photos: this.chat_data.user_photos, index: index });
      this.SetPrivateFlag(false);
      this.SetPopupName('view_photo');
      this.SetPopupStatus(true);
    },
    PrevSlide() {
      this.swiper.slidePrev();
    },
    NextSlide() {
      this.swiper.slideNext();
    },
    onSwiper(swiper) {
      //console.log(swiper);
      this.swiper = swiper;
    },
    onSlideChange() {
      // console.log('slide change')
    },
  }
}
</script>

<style scoped>

</style>