<template>
  <button
      class="g_button"
      :class="[
          button_style==='dark'?'button button-black-transparent':'',
          button_style==='plus'?'button button-black-transparent button-plus':'',
          button_style==='wallet'?'button button-wallet':'',
          button_style==='back'?'button button-black-transparent button-back':'',
          button_style==='transparent'?'button button-transparent':'',
          ]"
  >
    <span
        v-if="button_style === 'back' || button_style === 'wallet' || button_style === 'plus'">
      {{ text }}
    </span>
    <template v-else>{{ text }}</template>
  </button>
</template>

<script>
export default {
  name: "GButton",
  props: {
    text: {
      type:String,
      default:'Button'
    },
    button_style:{
      type: String,
      default: 'default' // dark, plus, wallet, back, transparent
      //button-black-transparent button-plus
      //button-black-transparent button-back
    },
  },
}
</script>

<style scoped></style>