<template>
  <section class="user">
    <div class="container">
      <div class="user-box">
        <picture class="user-box-bg">
          <img v-if="udata.banner_photo !== null" :src="api_server + '/' + udata.banner_photo" alt="">
        </picture>
        <div class="user-content">

          <div class="user-top">
            <div class="user-top-side">
              <!--              <GButton-->
              <!--                  text="Back"-->
              <!--                  button_style="back"-->
              <!--              />-->
            </div>
            <div class="user-top-side">
              <GButton
                  text="Add cover"
                  button_style="plus"
                  @click="Bg_File_Add"
              />
              <GButton
                  :text="'Your balance: '+ balance"
                  button_style="wallet"
              />
            </div>
          </div>

          <div class="user-main">

            <div class="user-avatar">
              <div class="user-avatar-image">
                <picture>
                  <img v-if="udata.main_photo !== null" :src="api_server + '/' + udata.main_photo" alt="">
                </picture>
              </div>
              <GButton
                  text="Upload new photo"
                  button_style="plus"
                  @click="Avatar_File_Add"
              />
            </div>

            <div class="user-settings">

              <div class="user-settings-block">
                <div class="user-info">

                  <label>Name</label>
                  <fieldset>
                    <input type="text" class="textfield" v-model="udata.user_name">
                  </fieldset>

                  <label>Birthday</label>
                  <fieldset>
                    <div class="user-birthday">
                      <div class="user-birthday-field">
                        <GSelect
                            v-model="udata.birthday_year"
                            :options="ageSelectOptions"
                            text-label="name"
                            placeholder=""
                            unic_id="birthday_year"
                            input_type="number"
                            class="user-select"
                        />
                      </div>
                      <div class="user-birthday-field">
                        <GSelect
                            v-model="udata.birthday_month"
                            :options="month"
                            text-label="name"
                            placeholder=""
                            unic_id="birthday_year"
                            input_type=""
                            class="user-select"
                        />
                      </div>
                      <div class="user-birthday-field">
                        <GSelect
                            v-model="udata.birthday_day"
                            :options="daySelectOptions"
                            text-label="name"
                            placeholder=""
                            unic_id="birthday_day"
                            input_type=""
                            class="user-select"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <label>I am</label>
                  <fieldset>
                    <div class="sex">
                      <label class="sex-male">
                        <input type="radio" value="true" name="user-sex" v-model="udata.male">
                        <span></span>
                      </label>
                      <label class="sex-female">
                        <input type="radio" value="false" name="user-sex" v-model="udata.male">
                        <span></span>
                      </label>
                    </div>
                  </fieldset>

                  <label>Language</label>
                  <fieldset>
                    <div class="user-info-lang">
                      <GSelect
                          v-model="udata.language"
                          :options="languages"
                          text-label="title"
                          placeholder=""
                          unic_id="main_language"
                          input_type=""
                          ZIndex="99"
                          class="user-select"
                      />
                    </div>
                  </fieldset>

                </div>
              </div>

              <div class="user-settings-block">
                <a href="#">Link with Google account</a>
              </div>

              <div class="user-settings-block">
                <a class="button" @click="GoToPreview">Preview my page</a>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router";
import GButton from "@/G_UI/GButton";
import GSelect from "@/G_UI/GSelect";

export default {
  name: "UserInfo",
  components: { GSelect, GButton },
  data: function () {
    return {
      minyear: 2003,
      deltayear: 99,
      month: [
        { id: 0, name: 'January' },
        { id: 1, name: 'February' },
        { id: 2, name: 'March' },
        { id: 3, name: 'April' },
        { id: 4, name: 'May' },
        { id: 5, name: 'June' },
        { id: 6, name: 'July' },
        { id: 7, name: 'August' },
        { id: 8, name: 'September' },
        { id: 9, name: 'October' },
        { id: 10, name: 'November' },
        { id: 11, name: 'December' },
      ],
      day: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      lang: ['English', 'French', 'Arabic'],

      balance: 150,

      avatar_file: null,
      bg_file: null,

      server_update: false,
      udata: {
        user_name: "",
        main_photo: null, //если пусто, то null
        banner_photo: null, //если пусто, то null
        male: true,
        language: null, //id from user_info.languages
        min_year: 2003,
        birthday_day: null,
        birthday_month: null,
        birthday_year: null,
      },
      pre_data: {},

      update_timeout: undefined,
    }
  },
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
      user_data: state => state.User.user_data,
      user_id: state => state.User.user_data.id,
      languages: state => state.User.languages,
    }),
    ageSelectOptions() {
      let tempArray = [];

      for (let i = 1; i < this.deltayear; i++) {
        //console.log(this.data.min_year, i);

        let tempObj = {
          id: this.udata.min_year - i,
          name: this.udata.min_year - i
        }

        tempArray.push(tempObj);
      }

      return tempArray;
    },
    daySelectOptions() {
      let tempArray = [];

      //day[udata.birthday_month]
      if (this.udata.birthday_month !== null && this.udata.birthday_month !== undefined) {
        console.log(this.day[this.udata.birthday_month], this.udata.birthday_month);
        for (let i = 1; i <= this.day[this.udata.birthday_month]; i++) {
          //console.log(this.udata.min_year, i);

          let tempObj = {
            id: i,
            name: i
          }

          tempArray.push(tempObj);
        }
      }

      return tempArray;
    }
  },
  mounted() {
    this.server_update = true;
    Object.assign(this.udata, this.user_data);
    Object.assign(this.pre_data, this.user_data);

    console.log(this.udata.user_name);

    setTimeout(() => {
      this.server_update = false
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus', 'SetUserData']),
    ...mapActions(['PutCall']),
    GoToPreview() {
      router.push({ path: '/user-view', query: { id: this.user_id } });
    },
    Avatar_File_Add() {
      this.SetPopupName('add_avatar');
      this.SetPopupStatus(true);
    },
    Bg_File_Add() {
      this.SetPopupName('add_bg');
      this.SetPopupStatus(true);
    },

    Save(key, value) {

      let form = {
        key: key,
        value: value
      }

      this.PutCall({
        endpoint: '/users/update',
        options: form,
        callback: (data) => {
          console.log(data);
          // this.SetUserData(data)
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    }
  },
  watch: {
    user_data: {
      handler(val) {
        this.server_update = true;
        console.log(val.birthday_day);
        Object.assign(this.udata, val);
        Object.assign(this.pre_data, val);

        console.log(this.udata.user_name);

        setTimeout(() => {
          this.server_update = false
        }, 100);
      },
      deep: true
    },
    udata: {
      handler(val) {
        console.log(this.server_update);
        if (!this.server_update) {

          let val_keys = Object.keys(val);
          let oldVal_keys = Object.keys(this.pre_data);

          let i, j, val_length = val_keys.length, oldVal_length = oldVal_keys.length;
          console.log(Object.keys(this.pre_data));
          for (i = 0; i < val_length; i++) {
            for (j = 0; j < oldVal_length; j++) {
              if (val_keys[i] === oldVal_keys[j]) {
                if (val[val_keys[i]] !== this.pre_data[oldVal_keys[j]]) {

                  console.log(val_keys[i], val[val_keys[i]], this.pre_data[oldVal_keys[j]]);

                  if(val_keys[i] === 'user_name'){
                    if(this.update_timeout !== undefined){
                      clearTimeout(this.update_timeout);
                    }
                    this.update_timeout = setTimeout(()=>{
                      this.Save('user_name', this.udata.user_name);
                    },1000);
                  }else{
                    this.Save(val_keys[i], val[val_keys[i]]);
                  }
                  Object.assign(this.pre_data, val);
                }
              }
            }
          }
        }
      },
      deep: true,
    }
  },

}
</script>

<style scoped>

</style>