import router from '@/router/index.js'

export default {
  state: {
    is_auth: false,
    trigger_is_auth: false,

    user_data: null,

    about_me_dd_data: null,
    my_lifestyle_dd_data: null,
    looking_for_dd_data: null,
    languages: null,
    interests: null,

    draw_scope_loaded: false,

  },
  mutations: {
    SetDrawScopeLoaded(state, status) {
      state.draw_scope_loaded = status;
    },
    SetUserType(state, type) {
      state.usertype = type;
    },
    SetLoginStatus(state, action) {
      state.is_auth = action;
    },
    SetLoginStatusTrigger(state) {
      state.trigger_is_auth = true;
    },
    SetLoginStatusTriggerEnd(state) {
      state.trigger_is_auth = false;
    },

    SetUserData(state,data){
      state.user_data = data.data.user_info;
    },

    SetUserBg(state,path){
      state.user_data.banner_photo = path;
    },
    SetUserAvatar(state,path){
      state.user_data.main_photo = path;
    },
    AddPhotoToList(state,data){
      if(!state.user_data.photos){
        state.user_data.photos = [];
      }
      state.user_data.photos.push(data);
    },
    DeletePhotoFromList(state,data){
      if(state.user_data.photos){
        let index = state.user_data.photos.map(e=>{return e.id;}).indexOf(data.id);
        state.user_data.photos.splice(index,1);
      }
    }
  },
  actions: {
    SetUserData({ state }, data){
      state.user_data = data;
    },
    SetLanguagesData({ state }, data){
      state.languages = data;
    },
    SetInterestData({ state }, data){
      state.interests = data;
    },
    SetAboutMeData({ state }, data){
      state.about_me_dd_data = data;
    },
    SetLifestyleData({ state }, data){
      state.my_lifestyle_dd_data = data;
    },


    Logout({ dispatch }) {
      dispatch('DeleteCredentials');
    },
    DeleteCredentials({ commit }) {
      console.log('DeleteCredentials');
      router.push({path:'/'});

      commit('SetLoginStatus', false);

      let l_storage = window.localStorage;

      l_storage.removeItem('token');
    },
    GetScopeUpdate({ dispatch }){
      dispatch('GetCall',{
        // endpoint: '/api/scope',
        endpoint: '/users/scope',
        options: {list:"user,languages,interest,about_drops,life_drops,photos"},
        callback: (data) => {
          dispatch('SetUserData',data.user);
          dispatch('SetLanguagesData',data.languages);
          dispatch('SetInterestData',data.interest);
          dispatch('SetAboutMeData',data.about);
          dispatch('SetLifestyleData',data.my_lifestyle);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      },{root:true})
    }
  },
  getters: {}
}
