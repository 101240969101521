<template>
  <div v-if="chat_type===0 || chat_type=== 2" class="chat-start">
    <div class="chat-start-box">
      <button type="button" class="button button-chat">Start chat</button>
    </div>
    <div class="chat-start-box">
      <button type="button" class="button button-mail" @click="WriteReply">Reply</button>
    </div>
  </div>
  <form v-if="chat_type===1" class="chat-form">
    <textarea class="textfield" placeholder="My message..."></textarea>
    <ul class="chat-form-buttons" v-if="user_data">
      <li>
        <button type="button" class="chat-form-translate"></button>
      </li>
      <li v-if="!user_data.is_seller">
        <button type="button" class="chat-form-present"></button>
      </li>
      <li v-if="user_data.is_seller">
        <button type="button" class="chat-form-video"></button>
      </li>
      <li v-if="!user_data.is_seller">
        <button type="button" class="chat-form-photo" @click="OpenChatAdd"></button>
      </li>
      <li>
        <button type="button" class="chat-form-smile"></button>
      </li>
      <li>
        <button type="submit" class="chat-form-send-message"></button>
      </li>
    </ul>
    <div class="chat-form-present-box">
      <div data-simplebar>
        <ul>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-1.png" alt="">
              <span>2 900 coins</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="pic/present-2.png" alt="">
              <span>1 700 coins</span>
            </a>
          </li>
        </ul>
      </div>
      <button type="button" class="chat-form-present-box-close"></button>
    </div>
  </form>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "CHT_SendField",
  props: {},
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
      chat_type: state => state.Chat.chat_type,
    }),
  },
  emits: [
    'onWrite'
  ],
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus', 'SetPrivateFlag', 'SetChatData']),
    WriteReply() {
      this.SetChatData({write_letter: true});
    },
    OpenChatAdd(e) {
      e.preventDefault();

      this.SetPrivateFlag(false);
      this.SetPopupName('chat_add');
      this.SetPopupStatus(true);
    }
  }
}
</script>

<style scoped>

</style>