<template>
  <main>
    <div class="container">
      <section class="search-result" v-if="search_result">
        <template v-for="(value,index) in search_result" v-bind:key="index">
          <article>
            <div class="person tip">
              <div
                  class="person-image"
                  @mouseover="value.hover = true"
                  @mouseleave="value.hover = false"
              >
                <picture>
                  <img :src="api_server + '/' + value.main_photo" alt="">
                </picture>
                <img v-if="value.mood_id" :src="'img/mood-'+(value.mood+1)+'.svg'" class="person-mood" alt="">
                <ul class="person-media">
                  <li class="person-photo" v-if="value.photos_num">{{ value.photos_num }}</li>
                  <li class="person-video" v-if="value.videos_num">{{ value.videos_num }}</li>
                </ul>
              </div>
              <div class="person-main">
                <div class="person-main-row">
                  <h2 class="person-name is-online" v-if="value.country">{{ value.user_name }}, {{ age(value.birthday_day,value.birthday_month,value.birthday_year) }}, {{ value.country }}</h2>
                  <h2 class="person-name is-online" v-else-if="value.birthday_day">{{ value.user_name }}, {{ age(value.birthday_day,value.birthday_month,value.birthday_year) }}</h2>
                  <a @click="GoToPath('/user-view',{id:value.id})" class="person-profile">Profile</a>
                </div>
              </div>
            </div>
            <div
                class="tip-content"
                :class="value.hover ? 'show':''"
                :id="'person-'+index"
                @mouseover="value.hover = true"
                @mouseleave="value.hover = false"
            >
              <div class="person-info">
                <div class="person-info-text" v-html="value.about_text"></div>
                <div class="person-info-interests">
                  <strong>My interests:</strong>
                  <ul class="interests">
                    <template v-for="(value2,index2) in value.interest" v-bind:key="index2">
                      <template v-for="(value3,index3) in interests" v-bind:key="index3">
                        <template v-if="value2.item_option_id === value3.id">
                          <li v-if="index2<5">
                            <label class="interest">
                              <input type="checkbox" checked disabled>
                              <span class="interest-content">
                                <i v-html="value3.icon"/>
                                <span>{{ value3.title }}</span>
                              </span>
                            </label>
                          </li>
                        </template>
                      </template>
                    </template>
                    <li v-if="value.interest.length > 5">
                      <a @click="GoToPath('/user-view',{id:value.id})" class="interest-more">
                        <i></i>
                        <span>{{ value.interest.length - 5 }} more </span>
                      </a>
                    </li>

                  </ul>
                </div>
                <div class="person-info-buttons">
                  <a href="#" class="button">Send letter</a>
                  <a @click="GoToPath('/chat',{id:value.id})" class="button">Chat now</a>
                </div>
              </div>
            </div>
          </article>
        </template>
      </section>
<!--      <div class="panel-bottom">
        <div class="panel-bottom-box">
          <ul class="pager">
            <li>
              <a href="#" class="pager-next disabled"></a>
            </li>
            <li>
              <span>630</span>
            </li>
            <li>...</li>
            <li>
              <a href="#">3</a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">1</a>
            </li>
            <li>
              <a href="#" class="pager-prev"></a>
            </li>
          </ul>
        </div>
        <div class="panel-bottom-box">
          <a href="#" class="button">Load more</a>
        </div>
      </div>-->
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "SearchResult",
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      interests: state => state.User.interests,
      search_result: state => state.Search.search_result,
    }),
  },
  mounted() {
    let ss = {
      "id": 1,
      "id_telegram": "383494944",
      "user_name": "vov_mi",
      "is_seller": false,
      "main_photo": "public/uploads/avatars/image-1657192530177-415963193avatar",
      "banner_photo": "public/uploads/covers/image-1657192550987-731802030bg",
      "male": true,
      "birthday_day": 25,
      "birthday_month": 0,
      "birthday_year": 1984,
      "about_text": "about me some text",
      "id_google": null,
      "language": 1,
      "mood_id": null,
      "interest": [
        {
          "id": 1,
          "item_option_id": 1,
          "user_id": 1
        },
        {
          "id": 2,
          "item_option_id": 2,
          "user_id": 1
        }
      ]
    }

    console.log(ss);
    
    if(this.search_result.length === 0){
      router.push({ path: '/user-profile', query: {} });
    }
  },
  data: function () {
    return {
      /*
      * {
  "id": 1,
  "id_telegram": "383494944",
  "user_name": "vov_mi",
  "is_seller": false,
  "main_photo": "public/uploads/avatars/image-1657192530177-415963193avatar",
  "banner_photo": "public/uploads/covers/image-1657192550987-731802030bg",
  "male": true,
  "birthday_day": 25,
  "birthday_month": 0,
  "birthday_year": 1984,
  "about_text": "about me some text",
  "id_google": null,
  "language": 1,
  "mood_id": null,
  "interest": [
    {
      "id": 1,
      "item_option_id": 1,
      "user_id": 1
    },
    {
      "id": 2,
      "item_option_id": 2,
      "user_id": 1
    }
  ]
}*/
      list: [
        {
          id: 0, name: 'Vasya', age: 25, country: 'UA', profile_link: '', img: 'pic/avatar-female-big.jpg', mood: 0, photos_num: 23,
          videos_num: 100,
          text: '<strong>About me:</strong> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal',
          interests: [
            {
              id: 0, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 1, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 2, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 3, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 4, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 5, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
          ]
        },
        {
          id: 0, name: 'Vasya', age: 25, country: 'UA', profile_link: '', img: 'pic/avatar-female-big.jpg', mood: 4, photos_num: 23,
          videos_num: 100,
          text: '<strong>About me:</strong> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal',
          interests: [
            {
              id: 0, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 1, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 2, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 3, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
          ]
        },
        {
          id: 0, name: 'Vasya', age: 25, country: 'UA', profile_link: '', img: 'pic/avatar-female-big.jpg', mood: 0, photos_num: 23,
          videos_num: 100,
          text: '<strong>About me:</strong> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal',
          interests: [
            {
              id: 0, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 1, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 2, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 3, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
          ]
        },
        {
          id: 0, name: 'Vasya', age: 25, country: 'UA', profile_link: '', img: 'pic/avatar-female-big.jpg', mood: 1, photos_num: 23,
          videos_num: 100,
          text: '<strong>About me:</strong> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal',
          interests: [
            {
              id: 0, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 1, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 2, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 3, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
          ]
        },
        {
          id: 0, name: 'Vasya', age: 25, country: 'UA', profile_link: '', img: 'pic/avatar-female-big.jpg', mood: 2, photos_num: 23,
          videos_num: 100,
          text: '<strong>About me:</strong> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal',
          interests: [
            {
              id: 0, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 1, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 2, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 3, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
          ]
        },
        {
          id: 0, name: 'Vasya', age: 25, country: 'UA', profile_link: '', img: 'pic/avatar-female-big.jpg', mood: 3, photos_num: 23,
          videos_num: 100,
          text: '<strong>About me:</strong> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal',
          interests: [
            {
              id: 0, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 1, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 2, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
            {
              id: 3, name: 'dancing',
              ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
            },
          ]
        },
      ]
    }
  },
  methods: {
    GoToPath(path,obj) {
      router.push({ path: path, query: obj });
    },

    age (birthday_day,birthday_month,birthday_year) {
      let age = '';
      if (birthday_day !== null && birthday_month !== null && birthday_year !== null) {

        let day = 1;
        let month = 0;
        let year = 1970;

        day = birthday_day;
        month = birthday_month;
        year = birthday_year;

        let user_date = new Date(year, month, day);
        let ageDifMs = Date.now() - user_date.getTime();
        let ageDate = new Date(ageDifMs);
        age = Math.abs(ageDate.getUTCFullYear() - 1970);
      }
      return age
    },

  },
}
</script>

<style scoped>

</style>