import axios from "axios";

export default {
	state: {},
	mutations: {},
	actions: {
		GetCall({ rootState, dispatch }, data_obj) {
			let options_str;
			if (data_obj.options === undefined) {
				options_str = '';
			} else {
				options_str = Object.keys(data_obj.options).reduce((prev, key, i) => (
					`${ prev }${ i !== 0 ? '&' : '' }${ key }=${ data_obj.options[key] }`
				), '');
			}
			if (options_str.length > 0) {
				options_str = '?' + options_str;
			}

			let path = rootState.Main.api_server + data_obj.endpoint + options_str;

			let l_storage = window.localStorage;
			let token = l_storage.getItem('token');

			console.log('Get', path);

			let headers = { 'Authorization': `Bearer ${ token }` }

			axios
				.get(path, { withCredentials: true, headers: headers })
				// .get(path)
				.then((response) => {
					console.log(response.data);

					switch (typeof data_obj.callback) {
						case "string":
							dispatch(data_obj.callback, response.data, { root: true })
							break;
						case "function":
							data_obj.callback(response.data);
							break;
					}
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.status);
						let error_status = error.response.status;

						if (error_status === 401) {
							dispatch('DeleteCredentials', { root: true });
						} else {
							switch (typeof data_obj.on_error) {
								case "string":
									dispatch(data_obj.on_error, error, { root: true })
									break;
								case "function":
									data_obj.on_error(error);
									break;
								default:
									break;
							}
						}
					} else {
						console.log(error);
					}
				});
		},
		PostCall({ rootState, dispatch }, data_obj) {
			if (data_obj.options === undefined) {
				data_obj.options = {}
			}

			let path = rootState.Main.api_server + data_obj.endpoint;

			let l_storage = window.localStorage;
			let token = l_storage.getItem('token');

			console.log('Post', path);

			let headers = { 'Authorization': `Bearer ${ token }` }

			console.log(data_obj.options);

			//let objstring = JSON.stringify(data_obj.options).replace('}','').replace('{','').replace(':','=').replace('"','').replace(',','\n');

			axios
				.post(path, data_obj.options, { withCredentials: true, headers })
				.then((response) => {
					console.log(response.data);

					switch (typeof data_obj.callback) {
						case "string":
							dispatch(data_obj.callback, response.data, { root: true })
							break;
						case "function":
							data_obj.callback(response.data);
							break;
					}

				})
				.catch((error) => {
					console.log('error', error);

					if (error.response) {
						console.log(error.response.status);
						let error_status = error.response.status;

						if (error_status === 401) {
							dispatch('DeleteCredentials', { root: true });
						} else {
							switch (typeof data_obj.on_error) {
								case "string":
									dispatch(data_obj.on_error, error, { root: true })
									break;
								case "function":
									data_obj.on_error(error);
									break;
								default:
									break;
							}
						}
					} else {
						console.log(error);
					}
				});
		},
		PutCall({ rootState, dispatch }, data_obj) {
			if (data_obj.options === undefined) {
				data_obj.options = {}
			}

			let path = rootState.Main.api_server + data_obj.endpoint;

			let l_storage = window.localStorage;
			let token = l_storage.getItem('token');

			console.log('Put', path);

			let headers = {
				'Authorization': `Bearer ${ token }`
			}

			axios
				.put(path, data_obj.options, { withCredentials: true, headers })
				.then((response) => {
					console.log(response.data);

					switch (typeof data_obj.callback) {
						case "string":
							dispatch(data_obj.callback, response.data, { root: true })
							break;
						case "function":
							data_obj.callback(response.data);
							break;
					}

				})
				.catch((error) => {
					console.log(error);

					if (error.response) {
						console.log(error.response.status);
						let error_status = error.response.status;

						if (error_status === 401) {
							dispatch('DeleteCredentials', { root: true });
						} else {
							switch (typeof data_obj.on_error) {
								case "string":
									dispatch(data_obj.on_error, error, { root: true })
									break;
								case "function":
									data_obj.on_error(error);
									break;
								default:
									break;
							}
						}
					} else {
						console.log(error);
					}
				});
		},
		DeleteCall({ rootState, dispatch }, data_obj) {
			let options_str;
			if (data_obj.options === undefined) {
				options_str = '';
			} else {
				options_str = Object.keys(data_obj.options).reduce((prev, key, i) => (
					`${ prev }${ i !== 0 ? '&' : '' }${ key }=${ data_obj.options[key] }`
				), '');
			}
			if (options_str.length > 0) {
				options_str = '?' + options_str;
			}

			let path = rootState.Main.api_server + data_obj.endpoint + options_str;

			let l_storage = window.localStorage;
			let token = l_storage.getItem('token');

			console.log('Delete', path);

			let headers = { 'Authorization': `Bearer ${ token }` }

			axios
				.delete(path, { withCredentials: true, headers })
				.then((response) => {
					console.log(response.data);

					switch (typeof data_obj.callback) {
						case "string":
							dispatch(data_obj.callback, response.data, { root: true });
							break;
						case "function":
							data_obj.callback(response.data);
							break;
					}
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.status);
						let error_status = error.response.status;

						if (error_status === 401) {
							dispatch('DeleteCredentials', { root: true });
						} else {
							switch (typeof data_obj.on_error) {
								case "string":
									dispatch(data_obj.on_error, error, { root: true });
									break;
								case "function":
									data_obj.on_error(error);
									break;
								default:
									break;
							}
						}
					} else {
						console.log(error);
					}
				});
		},
	},
	getters: {}
}