<template>
  <header>
    <div class="container">
      <router-link to="/user-profile" class="logo"></router-link>
      <ul class="header-links">
        <li>
          <a href="#" class="header-link header-links-mood">
            <span>My mood</span>
          </a>
        </li>
        <li>
          <div class="header-link header-links-filter" @click="OpenSearch">
            <span>Filter</span>
          </div>
        </li>
      </ul>
      <ul class="header-bar">
        <li>
          <a href="#" class="header-link header-bar-chat">
            <span>6</span>
          </a>
        </li>
        <li>
          <a href="#" class="header-link header-bar-notifications">
            <span>3</span>
          </a>
        </li>
        <li>
          <div class="header-link header-bar-fav">
            <span>22</span>
          </div>
        </li>
      </ul>
      <a href="#" class="button">Boost<span>&nbsp;account</span></a>
      <router-link to="/user-profile" class="header-user">
        <picture>
          <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">
          <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">
          <img src="/pic/avatar.png" alt="">
        </picture>
        <h1>Colin</h1>
      </router-link>
      <button type="button" class="header-nav-button" @click="OpenMenu">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </header>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Header",
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus', 'SetDDName', 'SetDDStatus', 'SetDDPosition']),
    //...mapActions(['PutCall']),
    OpenSearch() {
      this.SetPopupName('search');
      this.SetPopupStatus(true);
    },
    OpenMenu() {
      this.SetDDName('menu');
      let targ = document.getElementsByClassName('header-nav-button')[0];
      this.SetDDPosition({
        x: targ.getBoundingClientRect().x,
        y: targ.getBoundingClientRect().y,
        width: targ.clientWidth,
        height: targ.clientHeight
      })
      this.SetDDStatus(true);
    },
  },
}
</script>

<style scoped>

</style>