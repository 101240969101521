import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import Main from "@/components/00Main/Main";
import UserProfile from "@/components/01UserProfile/UserProfile";
import UserView from "@/components/02UserView/UserView";
import SearchResult from "@/components/03SearchResult/SearchResult";
import Chat from "@/components/04Chat/Chat";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresLogin: true },
  },
  {
    path: '/user-view',
    name: 'UserView',
    component: UserView,
    meta: { requiresLogin: true },
  },
  {
    path: '/search-result',
    name: 'SearchResult',
    component: SearchResult,
    meta: { requiresLogin: true },
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    meta: { requiresLogin: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.log(store.state.User.is_auth, store.state.User.usertype);

    if (!store.state.User.is_auth) {
      console.log('go to /');
      next({
        path: '/',
        //query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
