<template>
  <section class="box">
    <div class="box-header box-header-align-left">
      <div class="box-header-item">
        <h3>I'm looking for</h3>
      </div>
      <div class="box-header-item">
        <div class="sex">
          <label class="sex-male">
            <input type="checkbox" name="looking-sex" v-model="male">
            <span></span>
          </label>
          <label class="sex-female">
            <input type="checkbox" name="looking-sex" v-model="female">
            <span></span>
          </label>
        </div>
      </div>
      <div class="box-header-item">
        <div class="range">
<!--          <div class="range-box" data-min="0" data-max="100" data-start-min="22" data-start-max="56"></div>-->
<!--          <Slider-->
<!--              class="rules-range"-->
<!--              v-model="age"-->
<!--              :min="age_range_min"-->
<!--              :max="age_range_max"-->
<!--              :tooltips="true"-->
<!--          ></Slider>-->
          <GRange
              v-model="age"
              :range_min="age_range_min"
              :range_max="age_range_max"
              class="space_bottom_45"
          />

        </div>
      </div>
    </div>
    <div class="box-content">
      <form class="form">
        <fieldset>
          <label>Few words</label>
          <textarea class="textfield autosize" rows="1" v-model="text"></textarea>
        </fieldset>
        <div class="form-cols">
          <template v-for="(value, index) in about_me_dd_data" v-bind:key="index">
            <template v-if="looking_for.drops.map(e => { return e.item_id; }).indexOf(value.id) > -1">
              <template v-for="(value2, index2) in looking_for.drops" v-bind:key="index2">
                <UP_LookingFor_Elem v-if="value.id === value2.item_id" :data="value" :selected="value2"/>
              </template>
            </template>
            <template v-else>
              <UP_LookingFor_Elem :data="value" :selected="undefined"/>
            </template>
          </template>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
// import Slider from '@vueform/slider'
import UP_LookingFor_Elem from "@/components/01UserProfile/Parts/UP_LookingFor/UP_LookingFor_Elem";
import { mapActions, mapMutations, mapState } from "vuex";
import GRange from "@/G_UI/GRange";

export default {
  name: "UP_LookingFor",
  components:{
    GRange,
    // Slider,
    UP_LookingFor_Elem,
  },
  data:()=>{
    return {
      text:'',
      male: false,
      female: true,

      age_range_min: 19,
      age_range_max: 100,
      age: [22, 50],


      server_update: false,
      update_timeout: undefined,
      update_timeout_age_min: undefined,
      update_timeout_age_max: undefined,
    }
  },
  computed: {
    ...mapState({
      looking_for: state => state.User.user_data.looking_for,
      about_me_dd_data: state => state.User.about_me_dd_data,
    }),
  },
  mounted() {
    this.server_update = true;

    this.male = this.looking_for.male ? this.looking_for.male : false;
    this.female = this.looking_for.female ? this.looking_for.female : false;

    this.text = this.looking_for.text ? this.looking_for.text : '';

    if(this.looking_for.age_min !== '') {
      this.age[0] = this.looking_for.age_min;
    }else{
      this.age[0] = this.age_range_min;
    }
    if(this.looking_for.age_max !== '') {
      this.age[1] = this.looking_for.age_max;
    }else{
      this.age[1] = this.age_range_max;
    }

    setTimeout(()=>{this.server_update = false},100);
  },
  methods:{
    ...mapMutations(['SetUserData']),
    ...mapActions(['PutCall']),
    Save(key,val) {

      let form = {
        key: key,
        value: val
      }

      this.PutCall({
        endpoint: '/users/update_looking_static',
        options: form,
        callback: (data) => {
          console.log(data);
          // this.SetUserData(data);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    },

  },
  watch:{
    male: {
      handler(val,old) {
        if(!this.server_update) {
          if(val !== old) {
            this.Save('male',val);
          }
        }
      },
      deep: false
    },
    female: {
      handler(val,old) {
        if(!this.server_update) {
          if(val !== old) {
            this.Save('female',val);
          }
        }
      },
      deep: false
    },
    text: {
      handler(val,old) {
        //console.log(val,old,this.server_update);
        if(!this.server_update) {
          if(val !== old) {
            if(this.update_timeout !== undefined){
              clearTimeout(this.update_timeout);
            }
            this.update_timeout = setTimeout(()=>{
              this.Save('text',val);
            },1000);
          }
        }
      },
      deep: false
    },
    age: {
      handler(val,old) {
        console.log('age ',this.server_update, val, old);

        if(!this.server_update) {
          if(val[0] !== old[0]) {
            if(this.update_timeout_age_min !== undefined){
              clearTimeout(this.update_timeout_age_min);
            }
            this.update_timeout_age_min = setTimeout(()=>{
              this.Save('age_min',val[0]);
            },500);
          }
          if(val[1] !== old[1]) {
            if(this.update_timeout_age_max !== undefined){
              clearTimeout(this.update_timeout_age_max);
            }
            this.update_timeout_age_max = setTimeout(()=>{
              this.Save('age_max',val[1]);
            },500);
          }
        }
      },
      deep: true
    },

    looking_for: {
      handler(val) {
        this.server_update = true;

        this.male = val.male ? val.male : false;
        this.female = val.female ? val.female : false;

        this.text = val.text ? val.text : '';

        if(val.age_min !== '') {
          this.age[0] = val.age_min;
        }else{
          this.age[0] = this.age_range_min;
        }
        if(val.age_max !== '') {
          this.age[1] = val.age_max;
        }else{
          this.age[1] = this.age_range_max;
        }
        setTimeout(()=>{this.server_update = false},100);
      },
      deep: true,
    }
  },

}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.rules-range {
  position                      : relative;
  left                          : 0;
  top                           : 8px;

  /*margin                        : 25px 0 15px;*/

  --slider-bg                   : #E7ECF0;
  --slider-connect-bg           : #BA37D0;
  --slider-connect-bg-disabled  : #9CA3AF;
  --slider-height               : 6px;
  --slider-vertical-height      : 300px;
  --slider-radius               : 9999px;

  --slider-handle-bg            : #BA37D0;
  --slider-handle-border        : 0;
  --slider-handle-width         : 20px;
  --slider-handle-height        : 20px;
  --slider-handle-radius        : 9999px;
  --slider-handle-shadow        : 0.5px 0.5px 2px 1px rgba(144, 118, 221, 0.18);
  --slider-handle-shadow-active : 0.5px 0.5px 2px 1px rgba(144, 118, 221, 0.18);
  --slider-handle-ring-width    : 3px;
  --slider-handle-ring-color    : #9076DD2D;

  --slider-tooltip-bg           : #ffffff;
  --slider-tooltip-color        : #444444;
  --slider-tooltip-radius       : 5px;
  --slider-tooltip-min-width    : 20px;
  --slider-tooltip-font-size    : 0.875rem;
  --slider-tooltip-line-height  : 1.25rem;
  --slider-tooltip-font-weight  : 600;
  --slider-tooltip-py           : 2px;
  --slider-tooltip-px           : 6px;
  --slider-tooltip-arrow-size   : 5px;
  --slider-tooltip-distance     : 3px;
}
</style>