<template>
  <div class="chat-user"
       :class="chat_user.user_id === user.user_id ? 'is-active':''"
       @click="UserClicked">
    <div class="chat-user-avatar" :class="user.online ? 'is-online':''">
      <picture>
        <!--              <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
        <!--              <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
        <img :src="api_server+'/'+user.img" alt="">
      </picture>
    </div>
    <div class="chat-user-info">
      <div class="chat-user-name-date">
        <div class="chat-user-name">{{ user.name }}</div>
        <time class="chat-user-date">{{ user.last_date }}</time>
      </div>
      <div class="chat-user-links-about">
        <ul class="chat-user-links">
          <li class="chat-user-links-photo"></li>
          <li class="chat-user-links-video"></li>
        </ul>
        <div class="chat-user-about">{{ user.message }}</div>
        <div class="chat-user-messages-dummy" v-if="user.user_unread_count > 0">{{ user.user_unread_count }}</div>
        <div class="chat-user-unread-messages" v-if="user.user_unread_count > 0">{{ user.user_unread_count }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CHT_ChatElement",
  props: {
    user: Object,
  },
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
      chat_type: state => state.Chat.chat_type,
      chat_user: state => state.Chat.chat_user,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
    }
  },
  mounted() {
    if (this.user) {
      setTimeout(() => { this.UpdateChat();}, 150);
    }
  },
  methods: {
    ...mapActions(['GetCall']),
    ...mapMutations(['SetChatData']),
    UserClicked() {
      this.SetChatData({
        chat_id: this.user.user_id,
        chat_user: this.user,
      });
    },
    UpdateChat() {
      this.GetCall({
        endpoint: `/chat/messages/${this.user.id}`,
        options: {},
        callback: (data) => {
          this.SetChatData({chat_data: data});
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },

  },
}
</script>

<style scoped>

</style>