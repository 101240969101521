<template>
  <dialog class="popup" id="add-photos"
       :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add Background Image</div>
      <form class="form">
        <div class="popup-content">
          <fieldset>
            <label>Select Image</label>
            <div class="textfield-clear file-input-holder">
              <input type="file" class="file-input"  @change="FileAdded" accept=".png,.jpg">
              <span v-if="file === null">*.png,*.jpg</span>
              <span v-else class="file-selected">{{ file.name }}</span>
              <button v-if="file !== null" type="button" @click="ClearFile"></button>
            </div>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <button type="submit" class="button" @click="Save">Save Background</button>
          </div>
        </div>
      </form>
    </div>
  </dialog>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PAddBg",
  data: function () {
    return {
      is_open: false,
      file: null,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd','SetUserData','SetUserBg']),
    ...mapActions(['PostCall',]),


    FileAdded(e){
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0){
        this.file = files[0];
      }
    },


    ClearFile(){
      this.file = null
    },

    Save(e){
      e.preventDefault();

      let _file = this.file;

      const formData = new FormData();

      formData.append('type', 'background');
      formData.append('image', _file);

      this.PostCall({
        endpoint: '/users/update_image',
        options: formData,
        callback: (data) => {
          if(data) {
            this.SetUserBg(data.path);
          }
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
          this.hide();
        },
      })

    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>