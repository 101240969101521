export default {
  state: {
    chat_id: '',
    chat_type: 1,
    chat_num: 0,
    chat_users_list: null,
    chat_user: null,
    chat_data: null,

    letter: null,

    write_letter: false,
  },
  mutations: {
    SetChatData(state, data) {
      if (data.chat_id) {
        state.chat_id = data.chat_id;
      }
      if (data.chat_type || data.chat_type === 0) {
        state.chat_type = data.chat_type;
      }
      if (data.chat_users_list) {
        state.chat_users_list = Array.from(data.chat_users_list);
      }
      if (data.chat_user) {
        state.chat_user = {...data.chat_user};
      }
      if (data.chat_data) {
        state.chat_data = {...data.chat_data};
      }
      if (data.letter) {
        state.letter = {...data.letter};
      }
      if (data.write_letter) {
        state.write_letter = data.write_letter;
      }
    },
    ResetChatData(state) {
      state.chat_id = '';
      // state.chat_type = 1;
      state.chat_num = 0;
      state.chat_users_list = null;
      state.chat_user = null;
      state.chat_data = null;
      state.letter = null;
      state.write_letter = false;
    }
  },
  actions: {},
  getters: {}
}
