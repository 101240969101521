import { createStore } from 'vuex';
import Networking from '@/store/modules/Networking';
import Main from '@/store/modules/Main';
import Popups from "@/store/modules/Popups";
import User from "@/store/modules/User";
import Dropdowns from "@/store/modules/Dropdowns";
import Search from "@/store/modules/Search";
import Chat from "@/store/modules/Chat";
import FakeData from "@/store/modules/FakeData";


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Networking,
    Main,
    Popups,
    User,
    Dropdowns,
    Search,
    Chat,
    FakeData,
  }
})
