<template>
  <section class="box">
    <div class="box-header">
      <h3>A few words about myself</h3>
    </div>
    <div class="box-content">
      <form class="form">
        <fieldset>
          <GTextField
              v-model="text"
              placeholder=""
              class="textfield"
          />
<!--          <textarea class="textfield autosize" rows="1" v-model="text"></textarea>-->
        </fieldset>
        <GButton text="Save" class="button" @click="Save"/>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GTextField from "@/G_UI/GTextField";
import GButton from "@/G_UI/GButton";

export default {
  name: "UP_FewWords",
  components: { GButton, GTextField },
  data: function () {
    return {
      text:'',
    }
  },
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
    }),
  },
  mounted() {
    if(this.user_data) {
      if (this.user_data.about_text !== null) {
        this.server_update = true;
        this.text = this.user_data.about_text;
        setTimeout(()=>{this.server_update = false},100);
      }
    }
  },
  methods:{
    ...mapMutations(['SetUserData']),
    ...mapActions(['PutCall']),

    Save(e){
      e.preventDefault();

      let form = {
        key: 'about_text',
        value: this.text
      }

      this.PutCall({
        endpoint: '/users/update',
        options: form,
        callback: (data) => {
          console.log(data);
          // this.SetUserData(data)
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    }
  },
  watch:{
    user_data: {
      handler(val) {
        if(val) {
          if (val.about_user !== null) {
            this.server_update = true;
            this.text = val.about_user;
            setTimeout(()=>{this.server_update = false},100);
          }
        }
      },
      deep: true
    },
  },

}
</script>

<style scoped>

</style>