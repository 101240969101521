export default {
  state: {
    v: 'alpha-0.0.0.531',
    // debug: true,
    debug: false,
    // api_server: 'https://api.dating.mir.digital',
    api_server: 'https://api2.dating.mir.digital:8082',
    reload_flag: false,
    fullscreen_page: false,
  },
  mutations: {
    SetReloadFlag(state,reload){
      state.reload_flag = reload;
    },
    SetFullScreen(state,status){
      state.fullscreen_page = status;
    }
  },
  actions: {},
  getters: {}
}
