<template>
  <dialog class="popup sliding-door" id="chat-add">
    <div class="popup-box">
      <div class="sliding-door-top">
        <div class="chat-add-header"><span>Add your private photos</span> – you can choose 5 photos more</div>
        <form class="chat-add-search">
          <input type="text" class="textfield" value="Weekend" placeholder="Search...">
          <button type="submit"></button>
        </form>
        <button type="button" class="popup-close" @click="hide"></button>
      </div>
      <perfect-scrollbar :watchOptions="true"
                         :options="scroll_options"
                         class="DDHolder">
        <div class="sliding-door-main">

          <div class="chat-add-result" :class="show_selected?'':'chat-add-result-small'">
            <div class="chat-add-grid">
              <template v-for="(value, index) in photos" v-bind:key="index">
                <div class="chat-add-item" v-if="value.selected">
                  <div class="chat-add-item-image">
                    <picture>
                      <!--                    <source srcset="pic/chat-add.webp 1x, pic/chat-add@2x.webp 2x" type="image/webp">-->
                      <!--                    <source srcset="pic/chat-add.jpg 1x, pic/chat-add@2x.jpg 2x" type="image/jpeg">-->
                      <img :src="value.pic" alt="">
                    </picture>
                    <label>
                      <input type="checkbox" v-model="value.selected">
                      <span></span>
                    </label>
                  </div>
                  <div class="chat-add-item-price">
                    <span>Price</span>
                    <div class="count">
                      <button type="button" class="count-minus" @click="MinusPrice(value)"></button>
                      <input type="text" class="textfield only-numbers" v-model="value.price" maxlength="3">
                      <button type="button" class="count-plus" @click="PlusPrice(value)"></button>
                    </div>
                    <span>Coins</span>
                  </div>
                  <div class="chat-add-item-price-lite"><span>Price:</span> {{ value.price }}</div>
                  <div class="chat-add-item-title">{{ value.description }}</div>
                </div>
              </template>
            </div>
            <button type="button" v-if="!show_selected" class="button button-gray button-block chat-add-result-show" @click="ToggleShowSelected">Show selected</button>
            <button type="button" v-if="show_selected" class="button button-block chat-add-result-hide" @click="ToggleShowSelected">Hide selected</button>
          </div>
          <div class="chat-add-list">
            <div class="chat-add-grid">

              <template v-for="(value, index) in photos" v-bind:key="index">
                <div class="chat-add-item">
                  <div class="chat-add-item-image">
                    <picture>
                      <!--                    <source srcset="pic/chat-add.webp 1x, pic/chat-add@2x.webp 2x" type="image/webp">-->
                      <!--                    <source srcset="pic/chat-add.jpg 1x, pic/chat-add@2x.jpg 2x" type="image/jpeg">-->
                      <img :src="value.pic" alt="">
                    </picture>
                    <label>
                      <input type="checkbox" v-model="value.selected">
                      <span></span>
                    </label>
                  </div>
                  <div class="chat-add-item-price">
                    <span>Price</span>
                    <div class="count">
                      <button type="button" class="count-minus" @click="MinusPrice(value)"></button>
                      <input type="text" class="textfield only-numbers" v-model="value.price" maxlength="3">
                      <button type="button" class="count-plus" @click="PlusPrice(value)"></button>
                    </div>
                    <span>Coins</span>
                  </div>
                  <div class="chat-add-item-price-lite"><span>Price:</span> {{ value.price }}</div>
                  <div class="chat-add-item-title">{{ value.description }}</div>
                </div>
              </template>

            </div>
          </div>

        </div>
      </perfect-scrollbar>
      <div class="sliding-door-bottom center">
        <button type="button" class="button">Add {{ selected_num }} photos</button>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PChatPhotoAdd",
  data: function () {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      show_selected: false,
      is_open: false,
      photos: [
        { id: 0, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 1, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 2, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 3, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 4, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 5, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 6, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 7, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 8, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 9, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 10, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 11, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 12, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 13, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 14, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
        { id: 15, pic: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
      ]
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
    selected_num() {
      let ret = 0;

      for (let i = 1; i < this.photos.length; i++) {
        if(this.photos[i].selected){
          ret++;
        }
      }

      return ret;
    },
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd', 'SetUserData', 'AddPhotoToList']),
    ...mapActions(['PostCall',]),


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
    PlusPrice(e) {
      if (e.price < 999) {
        e.price++;
      }
    },
    MinusPrice(e) {
      if (e.price > 0) {
        e.price--;
      }
    },
    ToggleShowSelected(){
      this.show_selected = !this.show_selected;
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>