<template>
  <div class="tip-content" id="burger-nav">
    <ul class="burger-nav">
      <li>
        <a href="#" class="burger-nav-search is-active">Let’s find someone</a>
      </li>
      <!--		<li>-->
      <!--			<a href="#" class="burger-nav-stream">Let’s STREAM</a>-->
      <!--		</li>-->
      <li>
        <a href="#" class="burger-nav-wallet">Мovement of coins</a>
      </li>
      <li>
        <a href="#" class="burger-nav-blacklist">BlackList</a>
      </li>
      <li class="burger-nav-divider"></li>
      <li>
        <a href="#" class="burger-nav-sound is-enabled">Sound enabled</a>
        <!--<a href="#" class="burger-nav-sound is-disabled">Sound disabled</a>-->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DDBurgerNav"
}
</script>

<style scoped>

</style>