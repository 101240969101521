<template>
  <div class="version">{{ v }}</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Version",
  computed: {
    ...mapState({
      v: state => state.Main.v,
    }),
  },
}
</script>

<style scoped>

</style>