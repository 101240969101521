<template>
  <fieldset>
    <label>{{ data.title }}</label>
    <select :id="'select-looking-for-'+data.id" @change="Save">
      <option v-if="selected === null || selected === undefined" label="select" disabled selected></option>
      <template v-for="(value, index) in data.options" v-bind:key="index">
        <template v-if="selected === null || selected === undefined">
          <option :value="value.id">{{ value.title }}</option>
        </template>
        <template v-else>
          <option v-if="value.id === selected.item_options_id" :value="value.id" selected>{{ value.title }}</option>
          <option v-else :value="value.id">{{ value.title }}</option>
        </template>
      </template>
    </select>

  </fieldset>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "UP_LookingFor_Elem",
  props:{
    data:Object,
    selected:Object
  },
  methods:{
    ...mapMutations(['SetUserData']),
    ...mapActions(['PutCall']),
    Save(e) {

      let form = {
        item_id: this.data.id,
        values:[e.target.value]
      }


      this.PutCall({
        // endpoint: '/api/profile/update_profile_looking_static',
        endpoint: '/users/update_looking',
        options: form,
        callback: (data) => {
          console.log(data);
          // this.SetUserData(data);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    },
  }

}
</script>

<style scoped>

</style>