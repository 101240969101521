<template>
  <dialog class="popup" id="add-videos">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" data-close></button>
      <div class="popup-header">Add videos</div>
      <form class="form">
        <div class="popup-content">
          <div class="popup-icons">
            <img src="img/youtube.svg" alt="">
<!--            <img src="img/facebook.svg" alt="">-->
<!--            <img src="img/tiktok.svg" alt="">-->
          </div>
          <fieldset>
            <label>Link to another resource</label>
            <div class="textfield-clear">
              <input type="text" class="textfield" value="https :// www.google.com.ua/search?tbm=ftrmpasfewojpow">
              <button type="button"></button>
            </div>
          </fieldset>
          <button type="button" class="button-link button-link-add">
            <i></i>
            <span>Add more link</span>
          </button>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <button type="submit" class="button">Save videos</button>
          </div>
        </div>
      </form>
    </div>
  </dialog>
</template>

<script>
export default {
  name: "PAddVideo"
}
</script>

<style scoped>

</style>