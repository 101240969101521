<template>
  <main>
    <div class="container"
         v-if="
         user_data !== null &&
         about_me_dd_data !== null &&
         my_lifestyle_dd_data !== null &&
         languages !== null &&
         interests !== null">
      <user-info/>
      <few-words/>
      <gifts v-if="user_data.is_seller"/>
      <photos/>
      <private-photos/>
      <videos/>
      <private-videos/>
      <interests/>
      <about-me/>
      <my-lifestyle/>
      <looking-for/>
    </div>
  </main>
  <button type="button" class="up"></button>
</template>

<script>
import UserInfo from "@/components/01UserProfile/Parts/UP_UserInfo";
import FewWords from "@/components/01UserProfile/Parts/UP_FewWords";
import Gifts from "@/components/01UserProfile/Parts/UP_Gifts";
import Photos from "@/components/01UserProfile/Parts/UP_Photos";

import PrivatePhotos from "@/components/01UserProfile/Parts/UP_PrivatePhotos";
import Videos from "@/components/01UserProfile/Parts/UP_Videos";
import PrivateVideos from "@/components/01UserProfile/Parts/UP_PrivateVideos";
import Interests from "@/components/01UserProfile/Parts/UP_Interests";
import AboutMe from "@/components/01UserProfile/Parts/UP_AboutMe";
import MyLifestyle from "@/components/01UserProfile/Parts/UP_MyLifestyle";
import LookingFor from "@/components/01UserProfile/Parts/UP_LookingFor";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "AddUser",
  components: {
    UserInfo, FewWords, Gifts, Photos, PrivatePhotos, Videos, PrivateVideos, Interests, AboutMe, MyLifestyle, LookingFor
  },
  data: function () {
    return {
      //user_info:{},
      //languages: [],
    }
  },
  computed: {
    ...mapState({
      debug: state => state.Main.debug,
      draw_scope_loaded: state => state.User.draw_scope_loaded,
      user_data: state => state.User.user_data,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      languages: state => state.User.languages,
      interests: state => state.User.interests,
    }),
  },
  mounted() {
    // this.GetUserData();
    // this.GetLanguagesData();
    // this.GetInterestData();
    // this.GetAboutMeDDData();
    // this.GetLifestyleDDData();

    this.GetCall({
      // endpoint: '/api/scope',
      endpoint: '/users/scope',
      options: {list:"user,languages,interest,about_drops,life_drops,photos"},
      callback: (data) => {
        this.SetUserData(data.user);
        this.SetLanguagesData(data.languages);
        this.SetInterestData(data.interest);
        this.SetAboutMeData(data.about);
        this.SetLifestyleData(data.my_lifestyle);
        this.SetDrawScopeLoaded(true);
      },
      on_error: (error) => {
        let error_status = error.response.status;
        console.log(error_status);
      },
    })
  },
  methods:{
    ...mapMutations(['SetDrawScopeLoaded']),
    ...mapActions([
        'GetCall','PutCall','PostCall','SetUserData','SetAboutMeData','SetLanguagesData','SetInterestData',
        'SetLifestyleData'
    ]),
  },
}
</script>

<style scoped>

</style>