export default {
	state: {
		search_result: [],
	},
	mutations: {
		SetSearchResult(state,data){
			state.search_result = Array.from(data);
		},
	},
	actions: {},
	getters: {}
}
