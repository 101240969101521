<template>
  <dialog class="popup" id="adv-search">
    <perfect-scrollbar
        :watchOptions="true"
        :options="scroll_options"
        class="popup-box"
        style="max-width: 736px;">
      <div class="popup-header popup-header-row">
        <div class="popup-header-box">Search for Your Matches</div>
        <div class="popup-header-box">
          <GButton
              text="Reset"
              button_style="transparent"
              @click="Reset"
          />
        </div>
      </div>
      <button type="button" class="popup-close" @click="hide"></button>
      <form class="form adv-search" :class="advanced ? 'is-opened':''">
        <div class="popup-block">
          <div class="adv-search-top">
            <div class="sex">
              <label class="sex-male">
                <input type="radio" name="@@name" checked>
                <span></span>
              </label>
              <label class="sex-female">
                <input type="radio" name="@@name">
                <span></span>
              </label>
            </div>
            <div class="range">
              <GRange
                  v-model="range"
                  :range_min="age_range_min"
                  :range_max="age_range_max"
                  class="space_bottom_45"
              />
              <!--              <div class="range-box" data-min="0" data-max="100" data-start-min="22" data-start-max="56"></div>-->
            </div>
          </div>
        </div>
        <div class="popup-block">
          <fieldset>
            <label>Country, city (Enter 2 or more countries separated by semicolons)</label>
            <input type="text" class="textfield">
          </fieldset>
        </div>
        <div class="popup-block">
          <div class="form-row">
            <div class="form-row-box">
              <label class="checkbox">
                <input type="radio" name="adv-search-status" id="adv-search-online" checked>
                <span>Online</span>
              </label>
            </div>
            <div class="form-row-box">
              <label class="checkbox">
                <input type="radio" name="adv-search-status" id="adv-search-offline">
                <span>Offline</span>
              </label>
            </div>
<!--            <div class="form-row-box">-->
<!--              <label class="checkbox">-->
<!--                <input type="checkbox">-->
<!--                <span>Avaliable for video Chat</span>-->
<!--              </label>-->
<!--            </div>-->
          </div>
        </div>
        <div class="popup-block">
          <ul class="tags">
            <!--            <li>-->
            <!--              <button type="button" class="button">Biking</button>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <button type="button" class="button">Dancing</button>-->
            <!--            </li>-->
          </ul>
        </div>
        <div class="adv-search-hidden">
          <button type="button" class="adv-search-close" @click="GoAdvanced(false)">Close more option</button>
          <div class="popup-block">
            <div class="form-title">Mood</div>
            <ul class="mood-select">
              <template v-for="(value,index) in filerMood" v-bind:key="index">
                <li>
                  <label>
                    <input type="checkbox" checked @click="MoodSelected(value.id)">
                    <span>
									<img :src="value.ico" alt="">
									<i>{{ value.name }}</i>
								</span>
                  </label>
                </li>
              </template>
            </ul>
          </div>
          <div class="popup-block">
            <ul class="adv-search-tabs-nav">
              <template v-for="(value,index) in categories" v-bind:key="index">
                <li>
                  <button
                      type="button"
                      class="button-link"
                      :class="value.selected ? 'is-active':''"
                      @click="CategoryGange(index)"
                  >{{ value.title }}
                  </button>
                </li>
              </template>
            </ul>
            <ul class="adv-search-tabs-content" v-if="categories.length > 0">
              <template v-for="(value,index) in categories[category_selected].options" v-bind:key="index">
                <li>
                  <label>
                    <input type="radio"
                           name="adv-search-tabs"
                           :id="'adv-search-tabs-'+index"
                           @click="CategorySelected(value.id)"
                           v-if="value.selected === true"
                           checked
                    >
                    <input type="radio"
                           name="adv-search-tabs"
                           :id="'adv-search-tabs-'+index"
                           @click="CategorySelected(value.id)"
                           v-else
                    >
                    <span>{{ value.title }}</span>
                  </label>
                </li>
              </template>
            </ul>
          </div>
          <div class="popup-block">
            <div class="form-title">
              <div class="form-title-row">
                <div class="form-title-box">Interests</div>
                <div class="form-title-box">
                  <div class="swiper-nav">
                    <button type="button" @click="goNext" class="swiper-prev"></button>
                    <button type="button" class="swiper-next"></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="adv-search-interests">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <template v-for="(value,index) in filterInterests" v-bind:key="index">
                    <div class="swiper-slide" @click="InterestSelected(value.id)">
                      <label class="interest">
                        <input type="checkbox">
                        <span class="interest-content">
                            <i v-html="value.icon"/>
                            <span>{{ value.title }}</span>
                          </span>
                      </label>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <button type="button" class="button button-transparent adv-search-open" @click="GoAdvanced">Add more option</button>
            <button type="submit" class="button" @click="GetResult">Show results</button>
          </div>
        </div>
      </form>
    </perfect-scrollbar>
  </dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GButton from "@/G_UI/GButton";
import GRange from "@/G_UI/GRange";
import router from "@/router";

// const swiper = useSwiper();

export default {

  name: "PSearch",
  components: {
    GRange, GButton,

  },
  /*
  *     new Swiper($('.adv-search-interests .swiper-container')[0],{
        loop: true,
        loopedSlides: $('.adv-search-interests .interest').length,
        slidesPerView: 'auto',
        speed: 500,
        spaceBetween: 15,
        navigation: {
            prevEl: $('.adv-search-interests').prev().find('.swiper-prev')[0],
            nextEl: $('.adv-search-interests').prev().find('.swiper-next')[0]
        }
    });
  * */
  data: function () {
    return {
      is_open: false,
      description: '',
      file: null,
      range: [20,80],
      age_range_min: 19,
      age_range_max: 100,
      advanced: false,
      scroll_options: {
        suppressScrollX: true,
      },
      filterInterests:[],
      categories: [],
      category_selected: 0,
      filterPattern: {
        mood: [],
        categories: [],
        interests: [],
      }
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      filerMood: state => state.FakeData.filerMood,
      // filterCategories: state => state.FakeData.filterCategories,
      // filterInterests: state => state.FakeData.filterInterests,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      interests: state => state.User.interests,
    }),
  },
  mounted() {
    this.filterInterests = Array.from(this.interests);

    this.categories = Array.from(this.about_me_dd_data);

    setTimeout(() => {
      this.is_open = true;

      for (let i = 0; i < this.categories.length; i++) {
        this.categories[i].selected = i === 0;
        //console.log(this.categories[i].selected);
      }
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd', 'SetUserData','SetSearchResult']),
    ...mapActions(['PostCall','GetCall']),

    goNext() {
      //this.swiper.slideTo(3);
      //this.swiper.slideNext()
    },

    GoAdvanced(act = true) {
      this.advanced = act;
    },

    Reset() {

    },

    CategoryGange(index) {
      this.category_selected = index;
      this.categories.forEach(elem => {elem.selected = false});
      this.categories[index].selected = true;
    },
    CategorySelected(id) {
      for(let i=0;i<this.categories[this.category_selected].options.length;i++){
        let elem = this.categories[this.category_selected].options[i];
        elem.selected = elem.id === id;
      }
    },
    MoodSelected(id) {
      let moodIndex = this.filterPattern.mood.indexOf(id);
      if (moodIndex === -1) {
        this.filterPattern.mood.push(id);
      } else {
        this.filterPattern.mood.splice(moodIndex, 1);
      }
    },
    InterestSelected(id) {
      let interestIndex = this.filterPattern.interests.indexOf(id);
      if (interestIndex === -1) {
        this.filterPattern.interests.push(id);
      } else {
        this.filterPattern.interests.splice(interestIndex, 1);
      }
    },


    GetResult(e){
      e.preventDefault();

      this.GetCall({
        endpoint: '/search/users',
        options: this.filterPattern,
        callback: (data) => {
          this.SetSearchResult(data);
          this.hide();
          router.push({ path: '/search-result'});
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>