<template>
  <Header v-if="show_header"/>
  <router-view/>
  <Footer/>
  <Overlay/>
  <Popups v-if="popup_opened"/>
  <Dropdowns v-if="dd_opened"></Dropdowns>
  <Version/>
</template>

<script>
import Version from "@/components/Version";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import Popups from "@/components/Popups/Popups";
import Overlay from "@/components/Popups/Overlay";
import { mapActions, mapMutations, mapState } from "vuex";
import Dropdowns from "@/components/Dropdowns/Dropdowns";

export default {
  name: 'App',
  components: {
    Dropdowns,
    Version,
    Header,
    Footer,
    Popups,
    Overlay,
  },
  data:()=>{
    return {
      show_header: false
    }
  },
  computed: {
    ...mapState({
      draw_scope_loaded: state => state.User.draw_scope_loaded,
      is_auth: state => state.User.is_auth,
      popup_opened: state => state.Popups.popup_opened,
      dd_opened: state => state.Dropdowns.dd_opened,
    }),
  },
  mounted() {
    let l_storage = window.localStorage;

    let token = l_storage.getItem('token');

    if (token !== null && token !== undefined) {
      this.SetLoginStatus(true);
    }else{
      this.DeleteCredentials();
    }
  },
  methods:{
    ...mapMutations(['SetLoginStatus']),
    ...mapActions(['DeleteCredentials','GetScopeUpdate'])
  },
  watch: {
    popup_opened(newVal) {
      let body = document.body;
      if (newVal) {
        body.classList.add("is-locked");
      }else{
        body.classList.remove("is-locked");
      }
    },
    '$route'(to, from) {
      console.log('Route changed from ' + from.path + ' to ' + to.path);
      if (to.path === '/') {
        this.show_header = false;
        document.body.classList.add('intro-page');
        document.body.classList.remove('fullscreen-page');
      } else {
        this.show_header = true;
        document.body.classList.remove('intro-page');
        if (to.path !== '/user-profile' && to.path !== '/user-view') {
          if (!this.draw_scope_loaded) {
            this.GetScopeUpdate();
          }
        }
        if(to.path === '/chat'){
          document.body.classList.add('fullscreen-page')
        }else{
          document.body.classList.remove('fullscreen-page');
        }
      }
      document.body.classList.remove("is-locked");
    }
  }
}

</script>

<style lang="less">

</style>
