<template>
  <main class="intro">
    <button type="button" class="button button-black-transparent intro-video-toggle"></button>
    <!--
    <video id="intro-video" preload="auto" autoplay loop playsinline muted>
      <source src="video/intro.mp4" type="video/mp4">
      <source src="video/intro.webm" type="video/webm">
    </video>
    -->
    <div class="vimeo-wrapper">
      <iframe src="https://player.vimeo.com/video/640014466?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1" frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
    <header class="intro-logo">
      <img src="img/intro-logo.svg" alt="">
    </header>
    <aside class="intro-box">
      <h1 class="intro-info">
        <span class="intro-info-number">240 136</span>
        <span class="intro-info-text">people have already joined, <strong>jump in!</strong></span>
      </h1>
      <div class="intro-header">sign up / Sign in</div>
      <ul class="intro-buttons">
        <li>
          <div class="button button-block button-telegram">
            <telegram-login-temp
                mode="callback"
                telegram-login="dating_api_bot"
                @loaded='telegramLoadedCallbackFunc'
                @callback="yourCallbackFunction"
            />
          </div>
<!--          <telegram-login-temp
              mode="redirect"
              telegram-login="DatingBot"
              @loaded='telegramLoadedCallbackFunc'
              redirect-url="https://dating.mir.digital/telegram/login"
          />-->
        </li>
        <li>
          <a href="#" class="button button-block button-google">Sign in with Google</a>
        </li>
      </ul>
      <small class="intro-terms">By accessing the Site and/or using any other part of the Service, you agree to be bound by these <a
          href="#">terms and conditions</a></small>
    </aside>
  </main>
</template>

<script>
import { telegramLoginTemp } from 'vue3-telegram-login'
import { mapActions, mapMutations } from "vuex";
import router from "@/router";

export default {
  name: "Main",
  components: {
    telegramLoginTemp
  },
  data: function () {
    return {

    }
  },
  methods: {
    ...mapMutations(['SetLoginStatus']),
    ...mapActions(['PostCall','GetUserData']),
    telegramLoadedCallbackFunc() {
      console.log('script is loaded')
    },
    yourCallbackFunction(user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      console.log(user)//https://dating.mir.digital/telegram/login

      this.PostCall({
        endpoint: '/auth/telegram',
        options: user,
        callback: (data) => {
          //console.log(data);

          this.SetLoginStatus(true);

          let l_storage = window.localStorage;
          l_storage.setItem('token', data.access_token);

          //this.GetUserData();

          router.push({ path: '/user-profile'});

          // router.push({ path: '/add-user', query: { uuid: this.warehouse_uuid } });
        },
        on_error: (error) => {
          console.log(error);
          /*let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }*/
        },
      })
    }
  }
}
</script>

<style scoped>

</style>