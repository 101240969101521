<template>
  <section class="chat chat-invite">
    <CHT_LeftPart/>
    <CHT_Top/>
    <CHT_Window/>
    <CHT_SendField/>
  </section>
</template>

<script>
import CHT_LeftPart from "@/components/04Chat/Parts/CHT_LeftPart";
import CHT_Top from "@/components/04Chat/Parts/CHT_Top";
import CHT_Window from "@/components/04Chat/Parts/CHT_Window";
import CHT_SendField from "@/components/04Chat/Parts/CHT_SendField";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Invites",
  components: {CHT_SendField, CHT_Window, CHT_Top, CHT_LeftPart},
  computed: {
    ...mapState({
      chat_id: state => state.Chat.chat_id,
      chat_type: state => state.Chat.chat_type,
    }),
  },
  data: () => {
    return {
    }
  },
  mounted() {
    setTimeout(() => { this.GetUsersList();}, 50);
  },
  emits:[
    'onWrite'
  ],
  methods: {
    ...mapActions(['GetCall']),
    ...mapMutations(['SetChatData']),

    GetUsersList() {
      this.GetCall({
        endpoint: '/invites/list',
        options: {},
        callback: (data) => {
          this.SetChatData({chat_users_list:data});
          if(data.length > 0) {
            this.SetChatData({chat_user:data[0]});
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },

  },
}
</script>

<style scoped>

</style>