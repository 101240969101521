<template>
  <footer>
    <div class="container">
      <nav class="footer-nav">
        <ul>
          <li>
            <a href="#">News</a>
          </li>
          <li>
            <a href="#">F.A.Q</a>
          </li>
          <li>
            <a href="#">Terms and conditions</a>
          </li>
          <li>
            <a href="#">Love story</a>
          </li>
          <li>
            <a href="#">Contacts</a>
          </li>
        </ul>
      </nav>
      <div class="footer-copyright">&copy; 2021 All rights reserved</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>