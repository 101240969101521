<template>
  <div class="chat-search">
    <form class="chat-search-box">
      <input type="text" class="textfield" placeholder="Search">
      <button type="submit"></button>
    </form>
    <button type="button" class="chat-filter tip" data-tip="#chat-filter" data-tippy-placement="bottom-start"></button>
  </div>
  <div class="chat-list">
    <perfect-scrollbar :watchOptions="true" :options="scroll_options" class="DDHolder">
      <template v-if="chat_type===0">
        <template v-for="(value, index) in chat_users_list" v-bind:key="index">
          <CHT_InviteElement :user="value"/>
        </template>
      </template>
      <template v-if="chat_type===1">
        <template v-for="(value, index) in chat_users_list" v-bind:key="index">
          <CHT_ChatElement :user="value"/>
        </template>
      </template>
      <template v-if="chat_type===2">
        <template v-for="(value, index) in chat_users_list" v-bind:key="index">
          <CHT_LetterElement :user="value"/>
        </template>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CHT_InviteElement from "@/components/04Chat/Parts/CHT_LeftPart/CHT_InviteElement";
import CHT_ChatElement from "@/components/04Chat/Parts/CHT_LeftPart/CHT_ChatElement";
import CHT_LetterElement from "@/components/04Chat/Parts/CHT_LeftPart/CHT_LetterElement";

export default {
  name: "CHT_LeftPart",
  components:{CHT_InviteElement,CHT_ChatElement,CHT_LetterElement},
  computed: {
    ...mapState({
      api_server: state => state.Main.api_server,
      chat_type: state => state.Chat.chat_type,
      chat_users_list: state => state.Chat.chat_users_list,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
    }
  },
}
</script>

<style scoped>

</style>